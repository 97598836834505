import { managementServiceApi } from '../../../util/api';
import {
  CreateFloorResponse,
  CreateRoomResponse,
  FloorPostParams,
  FloorPutParams,
  GetFloorsResponse,
  GetRoomsResponse,
  RoomPostParams,
  RoomPutParams,
} from './types';

export default {
  getRooms: (urlParams: { configurationId: number }): Promise<GetRoomsResponse> => {
    return managementServiceApi(
      '/rooms',
      {
        method: 'GET',
      },
      urlParams,
    );
  },
  getFloors: (urlParams: { configurationId: number }): Promise<GetFloorsResponse> => {
    return managementServiceApi(
      '/floors',
      {
        method: 'GET',
      },
      urlParams,
    );
  },

  createRoom: (data: RoomPostParams): Promise<CreateRoomResponse> => {
    return managementServiceApi(`/rooms`, {
      method: 'POST',
      body: data,
    });
  },

  createFloor: (data: FloorPostParams): Promise<CreateFloorResponse> => {
    return managementServiceApi(`/floors`, {
      method: 'POST',
      body: data,
    });
  },

  updateRoom: (data: RoomPutParams, roomId: number): Promise<void> => {
    return managementServiceApi(`/rooms/${roomId}`, {
      method: 'PUT',
      body: data,
    });
  },
  updateFloor: (data: FloorPutParams, floorId: number): Promise<void> => {
    return managementServiceApi(`/floors/${floorId}`, {
      method: 'PUT',
      body: data,
    });
  },
  deleteFloor: (floorId: number): Promise<void> => {
    return managementServiceApi(`/floors/${floorId}`, {
      method: 'DELETE',
    });
  },
  deleteRoom: (roomId: number): Promise<void> => {
    return managementServiceApi(`/rooms/${roomId}`, {
      method: 'DELETE',
    });
  },
};
