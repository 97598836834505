import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';
import { AppStore } from '../../types';
import { isTokenCloseToExpiration } from '../../../util/tokenHelpers';
import { refreshToken, AuthAction } from '../../modules/auth/actions';
import { SWITCH_LANGUAGE, TOGGLE_COLLAPSED_NAV, WINDOW_WIDTH } from '../../../constants/ActionTypes';
import { AuthStatus } from 'appRedux/modules/auth/types';

let actionsBuffer: AnyAction[] = [];
const notPausedActions = [TOGGLE_COLLAPSED_NAV, WINDOW_WIDTH, SWITCH_LANGUAGE];

const refreshTokenMiddleware = (store: MiddlewareAPI<Dispatch, AppStore>) => (next: Dispatch) => (
  action: AnyAction,
) => {
  const { auth } = store.getState();
  const { data, authStatus } = auth;

  // If token refreshing is done, dispatch all actions in buffer and then clear buffer
  if (action.type === AuthAction.REFRESH_TOKEN_SUCCESS) {
    actionsBuffer.forEach((action) => store.dispatch(action));
    actionsBuffer = [];

    return next(action);
  }

  if (!data || action.type === AuthAction.REFRESH_TOKEN || action.type === AuthAction.REFRESH_TOKEN_FAILURE) {
    return next(action);
  }

  // If token is refreshing, push action to buffer
  if (authStatus === AuthStatus.REFRESHING) {
    if (notPausedActions.includes(action.type)) {
      return next(action);
    }

    actionsBuffer.push(action);

    return;
  }

  const { tokenExpirationTimestamp } = data;

  // If token is or close to expiration, push action to buffer and refresh token
  if (isTokenCloseToExpiration(tokenExpirationTimestamp) || action.payload?.error?.response?.status === '401') {
    store.dispatch(refreshToken());
    actionsBuffer.push(action);

    return;
  }

  return next(action);
};

export default refreshTokenMiddleware;
