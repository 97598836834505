import React from 'react';
import { Button, Space } from 'antd';
import { Form, Input } from 'formik-antd';
import IntlMessages from '../../../util/IntlMessages';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import { signIn } from '../../../appRedux/modules/auth/actions';
import { SignInFormData } from '../index';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthStatus } from 'appRedux/modules/auth/selectors';
import { AuthStatus } from 'appRedux/modules/auth/types';

const SignInForm = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const signInLoading = useSelector(selectAuthStatus);

  const areInputsDisabled = signInLoading === AuthStatus.PENDING;

  const validationSchema = yup.object().shape({
    username: yup
      .string()
      .min(3, intl.formatMessage({ id: 'appModule.shortValue' }))
      .max(50, intl.formatMessage({ id: 'appModule.longValue' }))
      .required(intl.formatMessage({ id: 'appModule.inputRequired' })),
    password: yup
      .string()
      .min(5, intl.formatMessage({ id: 'appModule.shortValue' }))
      .max(50, intl.formatMessage({ id: 'appModule.longValue' }))
      .required(intl.formatMessage({ id: 'appModule.inputRequired' })),
  });

  const handleSubmit = (values: SignInFormData) => {
    dispatch(signIn(values));
  };

  return (
    <Formik
      initialValues={{ username: '', password: '' }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnBlur
    >
      {({ errors, touched, values }) => (
        <Form className="gx-login-form">
          <Space direction="vertical">
            <Space direction="vertical" size={30}>
              <div className="input-group">
                <label
                  htmlFor="login-form-username"
                  className={`input-label${touched.username || values.username ? ' input-label-active' : ''}`}
                >
                  <IntlMessages id="appModule.username" />
                </label>
                <Input
                  name="username"
                  id="login-form-username"
                  disabled={areInputsDisabled}
                  className={errors.username && touched.username ? 'input-error' : ''}
                />
                {errors.username && touched.username && <div className="input-error-label">{errors.username}</div>}
              </div>
              <div className="input-group">
                <label
                  htmlFor="login-form-password"
                  className={`input-label${touched.password || values.password ? ' input-label-active' : ''}`}
                >
                  <IntlMessages id="appModule.password" />
                </label>
                <Input.Password
                  name="password"
                  id="login-form-password"
                  disabled={areInputsDisabled}
                  className={errors.password && touched.password ? 'input-error' : ''}
                />
                {errors.password && touched.password && <div className="input-error-label">{errors.password}</div>}
              </div>
            </Space>
            <div className="link-forgot-password-wrap">
              <a href="/" className="link-forgot-password">
                <IntlMessages id="app.userAuth.forgotPassword" />
              </a>
            </div>
            <Button className="submit-button" disabled={areInputsDisabled} htmlType="submit">
              <IntlMessages id="app.userAuth.signIn" />
            </Button>
            <div className="gx-app-login-footer">
              <IntlMessages id="app.userAuth.signUpMessage" />{' '}
              <Link to="/sign-up" className="link-sign-up">
                <IntlMessages id="app.userAuth.signUpLink" />
              </Link>
            </div>
          </Space>
        </Form>
      )}
    </Formik>
  );
};

export default SignInForm;
