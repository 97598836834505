import { useEffect, useRef } from 'react';

const useInterval = (callback: () => void, delay: number, isActive = true) => {
  const savedCallback = useRef();

  useEffect(() => {
    // @ts-ignore
    savedCallback.current = callback;
  });

  useEffect(() => {
    const tick = () => {
      // @ts-ignore
      savedCallback.current();
    };

    const id = isActive ? setInterval(tick, delay) : null;
    return () => {
      if (id) {
        clearInterval(id);
      }
    };
  }, [delay, isActive]);
};

export default useInterval;
