import { managementServiceApi } from '../../../util/api';
import { GetCountriesResponse } from './types';

export default {
  getCountries: (): Promise<GetCountriesResponse> => {
    return managementServiceApi('/countries', {
      method: 'GET',
    });
  },
};
