import { Opportunity, OpportunityPost, OpportunityPreview, OpportunityPut } from './types';
import { AxiosError } from 'axios';

export enum OpportunitiesAction {
  GET_OPPORTUNITIES = 'opportunities/GET_OPPORTUNITIES',
  GET_OPPORTUNITIES_SUCCESS = 'opportunities/GET_OPPORTUNITIES_SUCCESS',
  GET_OPPORTUNITIES_FAILURE = 'opportunities/GET_OPPORTUNITIES_FAILURE',
  CREATE_OPPORTUNITY = 'opportunities/CREATE_OPPORTUNITY',
  CREATE_OPPORTUNITY_SUCCESS = 'opportunities/CREATE_OPPORTUNITY_SUCCESS',
  CREATE_OPPORTUNITY_FAILURE = 'opportunities/CREATE_OPPORTUNITY_FAILURE',
  UPDATE_OPPORTUNITY = 'opportunities/UPDATE_OPPORTUNITIES',
  UPDATE_OPPORTUNITY_SUCCESS = 'opportunities/UPDATE_OPPORTUNITIES_SUCCESS',
  UPDATE_OPPORTUNITY_FAILURE = 'opportunities/UPDATE_OPPORTUNITIES_FAILURE',
  SELECT_OPPORTUNITY = 'opportunities/SELECT_OPPORTUNITY',
  GET_OPPORTUNITY = 'opportunities/GET_OPPORTUNITY',
  GET_OPPORTUNITY_SUCCESS = 'opportunities/GET_OPPORTUNITY_SUCCESS',
  GET_OPPORTUNITY_FAILURE = 'opportunities/GET_OPPORTUNITY_FAILURE',
  UPDATE_OPPORTUNITY_STATE = 'opportunities/UPDATE_UPPORTUNITY_STATE',
  UPDATE_OPPORTUNITY_STATE_SUCCESS = 'opportunities/UPDATE_UPPORTUNITY_STATE_SUCCESS',
  UPDATE_OPPORTUNITY_STATE_FAILURE = 'opportunities/UPDATE_UPPORTUNITY_STATE_FAILURE',
}

export type GetOpportunities = {
  type: OpportunitiesAction.GET_OPPORTUNITIES;
  payload: { organizationId: number; offset: number; limit: number };
};
export const getOpportunities = (organizationId: number, offset: number, limit: number): GetOpportunities => ({
  type: OpportunitiesAction.GET_OPPORTUNITIES,
  payload: { organizationId, offset, limit },
});

export type GetOpportunitiesSuccess = {
  type: OpportunitiesAction.GET_OPPORTUNITIES_SUCCESS;
  payload: { opportunities: OpportunityPreview[] };
};
export const getOpportunitiesSuccess = (opportunities: OpportunityPreview[]): GetOpportunitiesSuccess => ({
  type: OpportunitiesAction.GET_OPPORTUNITIES_SUCCESS,
  payload: { opportunities },
});

export type GetOpportunitiesFailure = {
  type: OpportunitiesAction.GET_OPPORTUNITIES_FAILURE;
  payload: { error: AxiosError };
};
export const getOpportunitiesFailure = (error: AxiosError): GetOpportunitiesFailure => ({
  type: OpportunitiesAction.GET_OPPORTUNITIES_FAILURE,
  payload: { error },
});

export type GetOpportunityById = { type: OpportunitiesAction.GET_OPPORTUNITY; payload: { id: number } };
export const getOpportunityById = (id: number): GetOpportunityById => ({
  type: OpportunitiesAction.GET_OPPORTUNITY,
  payload: { id },
});

export type GetOpportunityByIdSuccess = {
  type: OpportunitiesAction.GET_OPPORTUNITY_SUCCESS;
  payload: { opportunity: Opportunity };
};
export const getOpportunityByIdSuccess = (opportunity: Opportunity): GetOpportunityByIdSuccess => ({
  type: OpportunitiesAction.GET_OPPORTUNITY_SUCCESS,
  payload: { opportunity },
});

export type GetOpportunityByIdFailure = {
  type: OpportunitiesAction.GET_OPPORTUNITY_FAILURE;
  payload: { error: AxiosError };
};
export const getOpportunityByIdFailure = (error: AxiosError) => ({
  type: OpportunitiesAction.GET_OPPORTUNITY_FAILURE,
  payload: { error },
});

export type CreateOpportunity = {
  type: OpportunitiesAction.CREATE_OPPORTUNITY;
  payload: { opportunity: OpportunityPost };
};
export const createOpportunity = (opportunity: OpportunityPost): CreateOpportunity => ({
  type: OpportunitiesAction.CREATE_OPPORTUNITY,
  payload: { opportunity },
});

export type CreateOpportunitySuccess = { type: OpportunitiesAction.CREATE_OPPORTUNITY_SUCCESS };
export const createOpportunitySuccess = (): CreateOpportunitySuccess => ({
  type: OpportunitiesAction.CREATE_OPPORTUNITY_SUCCESS,
});

export type CreateOpportunityFailure = {
  type: OpportunitiesAction.CREATE_OPPORTUNITY_FAILURE;
  payload: { error: AxiosError };
};
export const createOpportunityFailure = (error: AxiosError): CreateOpportunityFailure => ({
  type: OpportunitiesAction.CREATE_OPPORTUNITY_FAILURE,
  payload: { error },
});

export type UpdateOpportunity = {
  type: OpportunitiesAction.UPDATE_OPPORTUNITY;
  payload: { id: number; opportunity: OpportunityPut };
};
export const updateOpportunity = (id: number, opportunity: OpportunityPut): UpdateOpportunity => ({
  type: OpportunitiesAction.UPDATE_OPPORTUNITY,
  payload: { id, opportunity },
});

export type UpdateOpportunitySuccess = { type: OpportunitiesAction.UPDATE_OPPORTUNITY_SUCCESS };
export const updateOpportunitySuccess = (): UpdateOpportunitySuccess => ({
  type: OpportunitiesAction.UPDATE_OPPORTUNITY_SUCCESS,
});

export type UpdateOpportunityFailure = {
  type: OpportunitiesAction.UPDATE_OPPORTUNITY_FAILURE;
  payload: { error: AxiosError };
};
export const updateOpportunityFailure = (error: AxiosError): UpdateOpportunityFailure => ({
  type: OpportunitiesAction.UPDATE_OPPORTUNITY_FAILURE,
  payload: { error },
});

export type UpdateOpportunityState = {
  type: OpportunitiesAction.UPDATE_OPPORTUNITY_STATE;
  payload: { projectId: number; stateId: number };
};
export const updateOpportunityState = (projectId: number, stateId: number): UpdateOpportunityState => ({
  type: OpportunitiesAction.UPDATE_OPPORTUNITY_STATE,
  payload: { projectId, stateId },
});
export type UpdateOpportunityStateSuccess = {
  type: OpportunitiesAction.UPDATE_OPPORTUNITY_STATE_SUCCESS;
  payload: { projectId: number; stateId: number };
};
export const updateOpportunityStateSuccess = (projectId: number, stateId: number): UpdateOpportunityStateSuccess => ({
  type: OpportunitiesAction.UPDATE_OPPORTUNITY_STATE_SUCCESS,
  payload: { projectId, stateId },
});
export type UpdateOpportunityStateFailure = {
  type: OpportunitiesAction.UPDATE_OPPORTUNITY_STATE_FAILURE;
  payload: { error: AxiosError };
};
export const updateOpportunityStateFailure = (error: AxiosError): UpdateOpportunityStateFailure => ({
  type: OpportunitiesAction.UPDATE_OPPORTUNITY_STATE_FAILURE,
  payload: { error },
});

export type OpportunitiesActionTypes =
  | GetOpportunities
  | GetOpportunitiesSuccess
  | GetOpportunitiesFailure
  | GetOpportunityById
  | GetOpportunityByIdSuccess
  | GetOpportunityByIdFailure
  | CreateOpportunity
  | CreateOpportunitySuccess
  | CreateOpportunityFailure
  | UpdateOpportunity
  | UpdateOpportunitySuccess
  | UpdateOpportunityFailure
  | UpdateOpportunityState
  | UpdateOpportunityStateSuccess
  | UpdateOpportunityStateFailure;
