import { HouseholdAction, HouseHoldActionTypes } from './actions';
import { HouseholdState } from './types';

export const initialState: HouseholdState = {
  floors: {
    error: null,
    loading: false,
    data: [],
    sending: false,
  },
  rooms: {
    error: null,
    loading: false,
    data: [],
    sending: false,
  },
  selectedRoom: null,
};

export default (state = initialState, action: HouseHoldActionTypes): HouseholdState => {
  switch (action.type) {
    case HouseholdAction.GET_FLOORS:
      return {
        ...state,
        floors: {
          ...state.floors,
          loading: true,
        },
      };
    case HouseholdAction.GET_FLOORS_SUCCESS:
      const { floors } = action.payload;
      return {
        ...state,
        floors: {
          ...state.floors,
          loading: false,
          data: floors,
        },
      };
    case HouseholdAction.GET_FLOORS_FAILURE:
      return {
        ...state,
        floors: {
          ...state.floors,
          loading: false,
          error: action.payload.error,
        },
      };
    case HouseholdAction.CREATE_FLOOR:
      return {
        ...state,
        floors: {
          ...state.floors,
          sending: true,
        },
      };
    case HouseholdAction.CREATE_FLOOR_SUCCESS:
      return {
        ...state,
        floors: {
          ...state.rooms,
          sending: false,
          data: [...state.floors.data, action.payload.floor],
        },
      };
    case HouseholdAction.CREATE_FLOOR_FAILURE:
      return {
        ...state,
        floors: {
          ...state.floors,
          loading: false,
          error: action.payload.error,
        },
      };
    case HouseholdAction.UPDATE_FLOOR:
      return {
        ...state,
        floors: {
          ...state.floors,
          sending: true,
        },
      };
    case HouseholdAction.UPDATE_FLOOR_SUCCESS:
      return {
        ...state,
        floors: {
          ...state.floors,
          sending: false,
        },
      };
    case HouseholdAction.UPDATE_FLOOR_FAILURE:
      return {
        ...state,
        floors: {
          ...state.floors,
          sending: false,
          error: action.payload.error,
        },
      };
    case HouseholdAction.DELETE_FLOOR:
      return {
        ...state,
        floors: {
          ...state.floors,
          sending: true,
        },
      };
    case HouseholdAction.DELETE_FLOOR_SUCCESS:
      const { id } = action.payload;
      return {
        ...state,
        ...(state.selectedRoom?.floorId === id && { selectedRoom: null }),
        floors: {
          ...state.floors,
          sending: false,
          data: state.floors.data.filter((floor) => floor.id !== id),
        },
        rooms: {
          ...state.rooms,
          data: state.rooms.data.filter((room) => room.floorId !== id),
        },
      };
    case HouseholdAction.DELETE_FLOOR_FAILURE:
      return {
        ...state,
        floors: {
          ...state.floors,
          sending: false,
          error: action.payload.error,
        },
      };
    case HouseholdAction.SELECT_ROOM:
      return {
        ...state,
        selectedRoom: action.payload.room,
      };
    case HouseholdAction.GET_ROOMS:
      return {
        ...state,
        rooms: {
          ...state.rooms,
          loading: true,
        },
      };
    case HouseholdAction.GET_ROOMS_SUCCESS:
      return {
        ...state,
        rooms: {
          ...state.rooms,
          loading: false,
          data: action.payload.rooms,
        },
      };
    case HouseholdAction.GET_ROOMS_FAILURE:
      return {
        ...state,
        rooms: {
          ...state.rooms,
          loading: false,
          error: action.payload.error,
        },
      };
    case HouseholdAction.CREATE_ROOM:
      return {
        ...state,
        rooms: {
          ...state.rooms,
          sending: true,
        },
      };
    case HouseholdAction.CREATE_ROOM_SUCCESS:
      return {
        ...state,
        rooms: {
          ...state.rooms,
          sending: false,
          data: [...state.rooms.data, action.payload.room],
        },
        selectedRoom: action.payload.room,
      };
    case HouseholdAction.CREATE_ROOM_FAILURE:
      return {
        ...state,
        rooms: {
          ...state.rooms,
          loading: false,
          error: action.payload.error,
        },
      };
    case HouseholdAction.UPDATE_ROOM:
      return {
        ...state,
        rooms: {
          ...state.rooms,
          sending: true,
        },
      };
    case HouseholdAction.UPDATE_ROOM_SUCCESS:
      return {
        ...state,
        rooms: {
          ...state.rooms,
          sending: false,
        },
      };
    case HouseholdAction.UPDATE_ROOM_FAILURE:
      return {
        ...state,
        rooms: {
          ...state.rooms,
          sending: false,
          error: action.payload.error,
        },
      };
    case HouseholdAction.DELETE_ROOM:
      return {
        ...state,
        rooms: {
          ...state.rooms,
          sending: true,
        },
      };
    case HouseholdAction.DELETE_ROOM_SUCCESS:
      return {
        ...state,
        ...(state.selectedRoom?.id === action.payload.id && { selectedRoom: null }),
        rooms: {
          ...state.rooms,
          sending: false,
          data: state.rooms.data.filter((room) => room.id !== action.payload.id),
        },
      };
    case HouseholdAction.DELETE_ROOM_FAILURE:
      return {
        ...state,
        rooms: {
          ...state.rooms,
          sending: false,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
};
