import React from 'react';
import { Spin } from 'antd';

interface Props {
  className?: string;
}

const CircularProgress = ({ className }: Props) => (
  <div className={`loader ${className ?? ''}`}>
    <Spin size="large" />
  </div>
);
export default CircularProgress;
