import React from 'react';
import { useSelector } from 'react-redux';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';

import CustomScrollbars from 'util/CustomScrollbars';
import SidebarLogo from './SidebarLogo';
import UserProfile from './UserProfile';
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from '../../constants/ThemeSetting';
import { ROUTES } from 'routes/app/routes';
import jwt_decode from 'jwt-decode';
import { selectAuthTokenData } from '../../appRedux/modules/auth/selectors';
import { adminPages } from '../../util/authentification';

const SidebarContent = () => {
  let { navStyle, themeType, pathname } = useSelector(({ settings }) => settings);

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return 'gx-no-header-notifications';
    }
    return '';
  };
  // const getNavStyleSubMenuClass = (navStyle) => {
  //   if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
  //     return "gx-no-header-submenu-popup";
  //   }
  //   return "";
  // };
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  const token = useSelector(selectAuthTokenData);
  const getRoleFromToken = () => {
    const accessToken = token.access_token;
    return jwt_decode(accessToken).role;
  };
  const renderRoutesByRoleFromToken = () => {
    const role = getRoleFromToken();
    return ROUTES.map(({ name, url, icon }) => {
      if (adminPages.includes(url) && role !== 'admin') return null;
      return (
        <Menu.Item key={url}>
          <Link to={url}>
            <i className={`icon icon-${icon}`} />
            <span>{name}</span>
          </Link>
        </Menu.Item>
      );
    });
  };

  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <UserProfile />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline"
          >
            {renderRoutesByRoleFromToken()}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};

export default SidebarContent;
