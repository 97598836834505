import { OrganizationCreateStatus, OrganizationsState } from './types';
import { OrganizationActionsType, OrganizationsActions } from './actions';

export const initialState: OrganizationsState = {
  createOrganizationStatus: OrganizationCreateStatus.NOT_CREATED,
  error: null,
};

export default (state = initialState, action: OrganizationActionsType): OrganizationsState => {
  switch (action.type) {
    case OrganizationsActions.CREATE_ORGANIZATION:
      return {
        ...state,
        createOrganizationStatus: OrganizationCreateStatus.PENDING,
      };
    case OrganizationsActions.CREATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        createOrganizationStatus: OrganizationCreateStatus.CREATED,
        error: null,
      };
    case OrganizationsActions.CREATE_ORGANIZATION_FAILURE:
      return {
        ...state,
        createOrganizationStatus: OrganizationCreateStatus.NOT_CREATED,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
