import { AppConfig } from './types';

const configBeta: AppConfig = {
  identityServer: {
    url: 'https://identity.beta.mujdum.online',
    clientId: 'cb6b4d57c4504fba8bfb350b3bf22862',
  },
  google: {
    clientId: '222278675509-ko55boa9tdg7cfgaemi4rvphiqpod4i3.apps.googleusercontent.com',
  },
  deploymentServiceUrl: 'https://update-test.aks.qest.cz/api',
  managementServiceUrl: 'https://partners.beta.mujdum.online/api/v1',
  gatewayServiceUrl: 'https://beta.mujdum.online/gateway/api/v1/',
  gdprLink: 'https://beta.mujdum.online/terms-and-conditions',
};

export default configBeta;
