import { CountriesState } from './types';
import { CountriesAction, CountriesActionTypes } from './actions';

export const initialState: CountriesState = {
  loading: false,
  error: null,
  data: [],
};

export default (state = initialState, action: CountriesActionTypes): CountriesState => {
  switch (action.type) {
    case CountriesAction.GET_COUNTRIES:
      return {
        ...state,
        loading: true,
      };
    case CountriesAction.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.countries,
      };
    case CountriesAction.GET_COUNTRIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
