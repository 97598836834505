import React from 'react';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { NotificationManager } from 'react-notifications';
import * as actions from './actions';
import apiService from './apiService';
import { createTokenExpirationTimestamp, createUsableToken } from '../../../util/tokenHelpers';
import {
  GetSignedUserResponse,
  RefreshTokenResponse,
  SignedUser,
  SignInResponse,
  TokenData,
  UserPutParams,
} from './types';
import { mapLanguageToSignUpLanguageId } from './utils';
import { selectAuthTokenData, selectSignedUser } from './selectors';
import config from '../../../config';
import IntlMessages from '../../../util/IntlMessages';
import { SWITCH_LANGUAGE } from 'constants/ActionTypes';
import { switchLanguage } from 'appRedux/actions';
import languageData from 'containers/Topbar/languageData';

function* watchSignIn() {
  yield takeLatest(actions.AuthAction.SIGN_IN, function* handle(action: ReturnType<typeof actions.signIn>) {
    const { values } = action.payload;
    const { username, password } = values;

    const data = new URLSearchParams();
    data.append('client_id', config.identityServer.clientId);
    data.append('username', username);
    data.append('grant_type', 'password');
    //Scope is returned by BE according to user permissions, not needed to set it implicitly.
    data.append('password', password);

    try {
      const response: SignInResponse = yield call(apiService.signIn, data);
      const token = createUsableToken(response.token_type, response.access_token);
      yield put(
        actions.signInSuccess({
          ...response,
          access_token: token,
          tokenExpirationTimestamp: createTokenExpirationTimestamp(response.expires_in),
        }),
      );
    } catch (e) {
      yield put(actions.signInFailure(e));
      if (e.response.status === 400) {
        NotificationManager.error(<IntlMessages id="appModule.signInInvalidCredentialsError" />);
      } else {
        NotificationManager.error(<IntlMessages id="appModule.signInError" />);
      }
    }
  });
}

function* watchSignWithGoogleIn() {
  yield takeLatest(actions.AuthAction.SIGN_IN_WITH_GOOGLE, function* handle(
    action: ReturnType<typeof actions.signInWithGoogle>,
  ) {
    const idToken = action.payload.response.tokenId;

    const data = new URLSearchParams();
    data.append('id_token', idToken);
    data.append('client_id', config.identityServer.clientId);
    data.append('grant_type', 'google');

    try {
      const response: SignInResponse = yield call(apiService.signIn, data);
      yield put(
        actions.signInSuccess({
          ...response,
          access_token: createUsableToken(response.token_type, response.access_token),
          tokenExpirationTimestamp: createTokenExpirationTimestamp(response.expires_in),
        }),
      );
      yield put(actions.saveGoogleUserInfo(action.payload.response.profileObj));
    } catch (e) {
      yield put(actions.signInFailure(e));
    }
  });
}

function* watchSignUp() {
  yield takeLatest(actions.AuthAction.SIGN_UP, function* handle(action: ReturnType<typeof actions.signUp>) {
    const { values, signInOnSuccess } = action.payload;

    const fullLanguage = navigator.language;
    const languageId = mapLanguageToSignUpLanguageId(fullLanguage);
    try {
      yield call(apiService.signUp, {
        ...values,
        languageId,
      });
      yield put(actions.signUpSuccess());
      if (signInOnSuccess) {
        const { email, password } = values;
        yield put(actions.signIn({ username: email, password }));
      }
    } catch (e) {
      yield put(actions.signUpFailure(e));

      if (e.response.status === 409) {
        NotificationManager.error(<IntlMessages id="app.userAuth.signUpError409" />);
      } else {
        NotificationManager.error(<IntlMessages id="app.userAuth.signUpError" />);
      }
    }
  });
}

function* watchCreateUser() {
  yield takeLatest(actions.AuthAction.CREATE_USER, function* handle(action: ReturnType<typeof actions.createUser>) {
    const userData = action.payload.data;
    try {
      yield call(apiService.createUser, userData);
      yield put(actions.createUserSuccess());
    } catch (e) {
      yield put(actions.createUserFailure(e));
      if (e.response.status !== 409) NotificationManager.error(<IntlMessages id="app.userAuth.createUserError" />);
    }
  });
}

function* watchRefreshToken() {
  yield takeLatest(actions.AuthAction.REFRESH_TOKEN, function* handle(action: ReturnType<typeof actions.refreshToken>) {
    const tokenData: TokenData | null = yield select(selectAuthTokenData);

    if (!tokenData) {
      return;
    }

    const { refresh_token } = tokenData;

    const data = new URLSearchParams();
    data.set('refresh_token', refresh_token);
    data.set('client_id', config.identityServer.clientId);
    data.set('grant_type', 'refresh_token');

    try {
      const response: RefreshTokenResponse = yield call(apiService.refreshToken, data);
      yield put(
        actions.refreshTokenSuccess({
          ...response,
          access_token: createUsableToken(response.token_type, response.access_token),
          tokenExpirationTimestamp: createTokenExpirationTimestamp(response.expires_in),
        }),
      );
    } catch (e) {
      yield put(actions.refreshTokenFailure(e));
    }
  });
}

function* watchGetSignedUser() {
  yield takeLatest(actions.AuthAction.GET_SIGNED_USER, function* handle(
    action: ReturnType<typeof actions.getSignedUser>,
  ) {
    try {
      const response: GetSignedUserResponse = yield call(apiService.getSignedUser);
      yield put(actions.getSignedUserSuccess(response));
      yield put(switchLanguage(languageData[response.languageId - 1]));
    } catch (e) {
      if (![441, 442, 443].includes(e.response.status)) {
        NotificationManager.error(<IntlMessages id="app.userAuth.getSignedUserError" />);
        yield put(actions.signOut());
      } else {
        yield put(actions.getSignedUserFailure(e));
      }
    }
  });
}

function* verifyEmail() {
  yield takeLatest(actions.AuthAction.VERIFY_EMAIL, function* handle(action: ReturnType<typeof actions.verifyEmail>) {
    const { email, token } = action.payload;
    try {
      yield call(apiService.verifyEmail, email, token);
      yield put(actions.verifyEmailSuccess());
    } catch {
      yield put(actions.verifyEmailFailure());
      NotificationManager.error(<IntlMessages id="app.userAuth.emailVerifyError" />);
    }
  });
}

function* watchSwitchLanguage() {
  yield takeLatest(SWITCH_LANGUAGE, function* handle(action: ReturnType<typeof switchLanguage>) {
    const { firstName, lastName, roles, id }: SignedUser = yield select(selectSignedUser);
    const newLanguageId = mapLanguageToSignUpLanguageId(action.payload.locale);
    const putParams: UserPutParams = { firstName, lastName, roles, languageId: newLanguageId };
    try {
      yield call(apiService.updateUser, putParams, id);
      yield put(actions.updateUserSuccess());
    } catch (error) {
      yield put(actions.updateUserFailure(error));
    }
  });
}

export default function* authSage() {
  yield fork(watchSignIn);
  yield fork(watchSignWithGoogleIn);
  yield fork(watchSignUp);
  yield fork(watchCreateUser);
  yield fork(watchRefreshToken);
  yield fork(watchGetSignedUser);
  yield fork(verifyEmail);
  yield fork(watchSwitchLanguage);
}
