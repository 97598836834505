import React from 'react';
import config from '../../../config';
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { googleLoginResponseIsNotOffline } from './typeGuards';
import { useDispatch } from 'react-redux';
import { signInWithGoogle } from '../../../appRedux/modules/auth/actions';

const SignInWithGoogle = () => {
  const dispatch = useDispatch();

  const handleOnSuccess = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if (googleLoginResponseIsNotOffline(response)) {
      dispatch(signInWithGoogle(response));
    }
  };

  return (
    <GoogleLogin
      theme="dark"
      className="google-signin-button"
      clientId={config.google.clientId}
      onSuccess={handleOnSuccess}
    />
  );
};

export default SignInWithGoogle;
