import { DeviceAction, DeviceActionTypes } from './actions';
import { DevicesState } from './types';

export const initialState: DevicesState = {
  devices: {
    error: null,
    data: [],
    loading: false,
    sending: false,
  },
  deviceTypes: {
    error: null,
    data: null,
    loading: false,
    sending: false,
  },
  deviceTypeCategories: {
    error: null,
    data: null,
    loading: false,
  },
  distributionBoards: {
    error: null,
    data: [],
    loading: false,
    sending: false,
  },
};

export default (state = initialState, action: DeviceActionTypes): DevicesState => {
  switch (action.type) {
    case DeviceAction.GET_DEVICES:
      return {
        ...state,
        devices: {
          ...state.devices,
          loading: true,
        },
      };
    case DeviceAction.GET_DEVICES_SUCCESS:
      return {
        ...state,
        devices: {
          ...state.devices,
          loading: false,
          data: action.payload.devices,
        },
      };
    case DeviceAction.GET_DEVICES_FAILURE:
      return {
        ...state,
        devices: {
          ...state.devices,
          loading: false,
          error: action.payload.error,
        },
      };
    case DeviceAction.CREATE_DEVICE:
      return {
        ...state,
        devices: {
          ...state.devices,
          sending: true,
        },
      };
    case DeviceAction.CREATE_DEVICE_SUCCESS:
      return {
        ...state,
        devices: {
          ...state.devices,
          sending: false,
          data: [...state.devices.data, action.payload.device],
        },
      };
    case DeviceAction.CREATE_DEVICE_FAILURE:
      return {
        ...state,
        devices: {
          ...state.devices,
          sending: false,
          error: action.payload.error,
        },
      };
    case DeviceAction.DELETE_DEVICE:
      return {
        ...state,
        devices: {
          ...state.devices,
          sending: true,
        },
      };
    case DeviceAction.DELETE_DEVICE_SUCCESS:
      return {
        ...state,
        devices: {
          ...state.devices,
          sending: false,
          data: state.devices.data.filter((device) => device.id !== action.payload.id),
        },
      };
    case DeviceAction.DELETE_DEVICE_FAILURE:
      return {
        ...state,
        devices: {
          ...state.devices,
          sending: false,
          error: action.payload.error,
        },
      };
    case DeviceAction.GET_DEVICE_TYPES:
      return {
        ...state,
        deviceTypes: {
          ...state.deviceTypes,
          loading: true,
        },
      };
    case DeviceAction.GET_DEVICE_TYPES_SUCCESS:
      return {
        ...state,
        deviceTypes: {
          ...state.deviceTypes,
          loading: false,
          data: action.payload.deviceTypes,
        },
      };
    case DeviceAction.GET_DEVICE_TYPES_FAILURE:
      return {
        ...state,
        deviceTypes: {
          ...state.deviceTypes,
          loading: false,
          error: action.payload.error,
        },
      };
    case DeviceAction.GET_DEVICE_TYPE_CATAGORIES:
      return {
        ...state,
        deviceTypeCategories: {
          ...state.deviceTypeCategories,
          loading: true,
        },
      };
    case DeviceAction.GET_DEVICE_TYPE_CATAGORIES_SUCCESS:
      return {
        ...state,
        deviceTypeCategories: {
          ...state.deviceTypeCategories,
          loading: false,
          data: action.payload.categories,
        },
      };
    case DeviceAction.GET_DEVICE_TYPE_CATAGORIES_FAILURE:
      return {
        ...state,
        deviceTypeCategories: {
          ...state.deviceTypeCategories,
          loading: false,
          error: action.payload.error,
        },
      };
    case DeviceAction.CREATE_DISTRIBUTION_BOARD:
      return {
        ...state,
        distributionBoards: {
          ...state.distributionBoards,
          sending: true,
        },
      };
    case DeviceAction.CREATE_DISTRIBUTION_BOARD_SUCCESS:
      const { data } = state.distributionBoards;
      return {
        ...state,
        distributionBoards: {
          ...state.distributionBoards,
          sending: false,
          data: data ? [...data, action.payload] : [action.payload],
          error: null,
        },
      };
    case DeviceAction.CREATE_DISTRIBUTION_BOARD_FAILURE:
      return {
        ...state,
        distributionBoards: {
          ...state.distributionBoards,
          sending: false,
          error: action.payload.error,
        },
      };
    case DeviceAction.GET_DISTRIBUTION_BOARDS:
      return {
        ...state,
        distributionBoards: {
          ...state.distributionBoards,
          loading: true,
        },
      };
    case DeviceAction.GET_DISTRIBUTION_BOARDS_SUCCESS:
      return {
        ...state,
        distributionBoards: {
          ...state.distributionBoards,
          loading: false,
          data: action.payload.distributionBoards,
          error: null,
        },
      };
    case DeviceAction.GET_DISTRIBUTION_BOARDS_FAILURE:
      return {
        ...state,
        distributionBoards: {
          ...state.distributionBoards,
          loading: false,
          error: action.payload.error,
        },
      };
    case DeviceAction.RESET_DEVICES:
      return initialState;
    default:
      return state;
  }
};
