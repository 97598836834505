import { call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import apiService from './apiService';
import { NotificationManager } from 'react-notifications';
import IntlMessages from '../../../util/IntlMessages';
import React from 'react';
import { Opportunity, OpportunityPreview } from './types';
import { resetConfigurations } from '../configurations/actions';
import { resetDevices } from '../devices/actions';

function* watchCreateOpportunity() {
  yield takeLatest(actions.OpportunitiesAction.CREATE_OPPORTUNITY, function* handle(
    action: ReturnType<typeof actions.createOpportunity>,
  ) {
    try {
      yield call(apiService.createOpportunity, action.payload.opportunity);
      yield put(actions.createOpportunitySuccess());
    } catch (e) {
      yield put(actions.createOpportunityFailure(e));
      NotificationManager.error(<IntlMessages id="app.opportunities.createError" />);
    }
  });
}

function* watchUpdateOpportunity() {
  yield takeLatest(actions.OpportunitiesAction.UPDATE_OPPORTUNITY, function* handle(
    action: ReturnType<typeof actions.updateOpportunity>,
  ) {
    try {
      yield call(apiService.updateOpportunity, action.payload.id, action.payload.opportunity);
      yield put(actions.updateOpportunitySuccess());
    } catch (e) {
      yield put(actions.updateOpportunityFailure(e));
      NotificationManager.error(<IntlMessages id="opportunities.updateError" />);
    }
  });
}

function* watchGetOpportunities() {
  yield takeLatest(actions.OpportunitiesAction.GET_OPPORTUNITIES, function* handle(
    action: ReturnType<typeof actions.getOpportunities>,
  ) {
    const { organizationId, offset, limit } = action.payload;
    try {
      const opportunityPreview: OpportunityPreview[] = yield call(
        apiService.getOpportunities,
        organizationId,
        offset,
        limit,
      );
      yield put(actions.getOpportunitiesSuccess(opportunityPreview));
    } catch (e) {
      yield put(actions.getOpportunitiesFailure(e));
    }
  });
}

function* watchGetOpportunityById() {
  yield takeLatest(actions.OpportunitiesAction.GET_OPPORTUNITY, function* handle(
    action: ReturnType<typeof actions.getOpportunityById>,
  ) {
    yield put(resetConfigurations());
    yield put(resetDevices());
    try {
      const opportunity: Opportunity = yield call(apiService.getOpportunityById, action.payload.id);
      yield put(actions.getOpportunityByIdSuccess(opportunity));
    } catch (e) {
      yield put(actions.getOpportunityByIdFailure(e));
    }
  });
}

function* watchUpdateOpportunityState() {
  yield takeEvery(actions.OpportunitiesAction.UPDATE_OPPORTUNITY_STATE, function* handle(
    action: ReturnType<typeof actions.updateOpportunityState>,
  ) {
    const { projectId, stateId } = action.payload;
    try {
      yield call(apiService.updateOpportunityState, projectId, stateId);
      yield put(actions.updateOpportunityStateSuccess(projectId, stateId));
    } catch (error) {
      yield put(actions.updateOpportunityStateFailure(error));
      NotificationManager.error(<IntlMessages id="opportunities.updateError" />);
    }
  });
}

export default function* countriesSage() {
  yield fork(watchCreateOpportunity);
  yield fork(watchUpdateOpportunity);
  yield fork(watchGetOpportunities);
  yield fork(watchGetOpportunityById);
  yield fork(watchUpdateOpportunityState);
}
