import { call, fork, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import apiService from './apiService';
import { GetCountriesResponse } from './types';
import { NotificationManager } from 'react-notifications';
import IntlMessages from '../../../util/IntlMessages';
import React from 'react';

function* watchGetCountries() {
  yield takeLatest(actions.CountriesAction.GET_COUNTRIES, function* handle(
    action: ReturnType<typeof actions.getCountries>,
  ) {
    try {
      const response: GetCountriesResponse = yield call(apiService.getCountries);
      yield put(actions.getCountriesSuccess(response));
    } catch (e) {
      yield put(actions.getCountriesFailure(e));
      NotificationManager.error(<IntlMessages id="app.countries.getError" />);
    }
  });
}

export default function* countriesSage() {
  yield fork(watchGetCountries);
}
