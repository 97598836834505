import { OrganizationPostBody } from './types';
import { AxiosError } from 'axios';

export enum OrganizationsActions {
  CREATE_ORGANIZATION = 'organizations/CREATE_ORGANIZATION',
  CREATE_ORGANIZATION_SUCCESS = 'organizations/CREATE_ORGANIZATION_SUCCESS',
  CREATE_ORGANIZATION_FAILURE = 'organizations/CREATE_ORGANIZATION_FAILURE',
}

export type CreateOrganization = {
  type: OrganizationsActions.CREATE_ORGANIZATION;
  payload: { organization: OrganizationPostBody };
};
export const createOrganization = (organization: OrganizationPostBody): CreateOrganization => {
  return {
    type: OrganizationsActions.CREATE_ORGANIZATION,
    payload: { organization },
  };
};

export type CreateOrganizationSuccess = { type: OrganizationsActions.CREATE_ORGANIZATION_SUCCESS };
export const createOrganizationSuccess = (): CreateOrganizationSuccess => {
  return {
    type: OrganizationsActions.CREATE_ORGANIZATION_SUCCESS,
  };
};

export type CreateOrganizationFailure = {
  type: OrganizationsActions.CREATE_ORGANIZATION_FAILURE;
  payload: { error: AxiosError };
};
export const createOrganizationFailure = (error: AxiosError): CreateOrganizationFailure => {
  return {
    type: OrganizationsActions.CREATE_ORGANIZATION_FAILURE,
    payload: { error },
  };
};

export type OrganizationActionsType = CreateOrganization | CreateOrganizationSuccess | CreateOrganizationFailure;
