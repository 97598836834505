import { AxiosError } from 'axios';
import { SignUpLanguage } from 'constants/constants';
import { GoogleLoginResponse } from 'react-google-login';

export interface AuthState {
  loading: {
    signUp: boolean;
    getSignedUser: boolean;
  };
  sending: boolean;
  error: AxiosError | null;
  data: TokenData | null;
  user: SignedUser | null;
  authStatus: AuthStatus;
  googleUserInfo: GoogleProfileObj | null;
  emailVerificationStatus: AuthStatus;
}

export interface TokenData extends SignInResponse {
  tokenExpirationTimestamp: number;
}

export interface SignedUser {
  email: string;
  organizationId: number;
  id: number;
  roles: UserRole[];
  firstName: string;
  lastName: string;
  verified: boolean;
  languageId: number;
}

export interface SignUpPostParams {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  languageId: SignUpLanguage;
}

export interface CreateUserPostParams {
  email: string;
  firstName: string;
  lastName: string;
  languageId: number;
}

export interface SignInResponse {
  access_token: string;
  expires_in: number;
  token_type: string;
  refresh_token: string;
  scope: string;
}

export interface UserPutParams {
  firstName: string;
  lastName: string;
  languageId: number;
  roles: UserRole[];
}

export enum UserRole {
  SALESMAN = 1,
  TECHNICIAN = 2,
  OWNER = 3,
}

export type GoogleProfileObj = GoogleLoginResponse['profileObj'];

export type RefreshTokenResponse = SignInResponse;

export type GetSignedUserResponse = SignedUser;

export enum AuthStatus {
  AUTHENTICATED,
  NOT_AUTHENTICATED,
  REFRESHING,
  PENDING,
}
