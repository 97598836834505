import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAuthTokenData,
  selectGoogleUserInfo,
  selectSignedUserError,
} from '../../appRedux/modules/auth/selectors';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { mapLanguageToSignUpLanguageId } from '../../appRedux/modules/auth/utils';
import { createUser } from '../../appRedux/modules/auth/actions';
import jwt_decode from 'jwt-decode';

const UserManager: FC = ({ children }) => {
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const signedUserError = useSelector(selectSignedUserError);
  const googleUserInfo = useSelector(selectGoogleUserInfo);
  const tokenData = useSelector(selectAuthTokenData);

  const status = signedUserError?.response?.status;

  if (status === 441) {
    return <Redirect to={`${match.url}email-verification`} />;
  }

  if (status === 442 && googleUserInfo) {
    const { email, givenName, familyName } = googleUserInfo;
    const languageId = mapLanguageToSignUpLanguageId(navigator.language);

    dispatch(createUser({ email, firstName: givenName, lastName: familyName, languageId }));

    return <Redirect to={`${match.url}new-organization`} />;
  }

  if (status === 442 && tokenData) {
    const { email, given_name, family_name } = jwt_decode(tokenData.access_token);
    const languageId = mapLanguageToSignUpLanguageId(navigator.language);

    dispatch(createUser({ email, firstName: given_name, lastName: family_name, languageId }));

    return <Redirect to={`${match.url}new-organization`} />;
  }

  if (status === 443) {
    return <Redirect to={`${match.url}new-organization`} />;
  }

  return <>{children}</>;
};

export default UserManager;
