import { AppStore } from '../../types';
import { createSelector } from 'reselect';

const selectAuthState = (state: AppStore) => state.auth;

export const selectAuthTokenData = createSelector(selectAuthState, (state) => state.data);
export const selectAuthStatus = createSelector(selectAuthState, (state) => state.authStatus);

export const selectSignUpLoading = createSelector(selectAuthState, (state) => state.loading.signUp);
export const selectUserSending = createSelector(selectAuthState, (state) => state.sending);

export const selectSignedUser = createSelector(selectAuthState, (state) => state.user);
export const selectSignedUserLoading = createSelector(selectAuthState, (state) => state.loading.getSignedUser);
export const selectSignedUserError = createSelector(selectAuthState, (state) => state.error);

export const selectGoogleUserInfo = createSelector(selectAuthState, (state) => state.googleUserInfo);

export const selectVerificationStatus = createSelector(selectAuthState, (state) => state.emailVerificationStatus);
