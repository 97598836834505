import { call, fork, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import apiService from './apiService';
import { NotificationManager } from 'react-notifications';
import IntlMessages from '../../../util/IntlMessages';
import React from 'react';

function* watchCreateOrganization() {
  yield takeLatest(actions.OrganizationsActions.CREATE_ORGANIZATION, function* handle(
    action: ReturnType<typeof actions.createOrganization>,
  ) {
    const { organization } = action.payload;

    try {
      yield call(apiService.createOrganization, organization);
      yield put(actions.createOrganizationSuccess());
    } catch (e) {
      yield put(actions.createOrganizationFailure(e));
      NotificationManager.error(<IntlMessages id="app.organizations.postError" />);
    }
  });
}

export default function* countriesSage() {
  yield fork(watchCreateOrganization);
}
