import { OpportunitiesAction, OpportunitiesActionTypes } from './actions';
import { OpportunitiesState } from './types';

export const initialState: OpportunitiesState = {
  loading: { opportunities: false, selectedOpportunity: false, stateUpdate: false },
  sending: { update: false, create: false },
  error: { opportunities: null, selectedOpportunity: null, update: null, create: null },
  data: { opportunities: [], selectedOpportunity: null },
};

export default (state = initialState, action: OpportunitiesActionTypes): OpportunitiesState => {
  switch (action.type) {
    case OpportunitiesAction.GET_OPPORTUNITIES:
      return {
        ...state,
        loading: { ...state.loading, opportunities: true },
      };
    case OpportunitiesAction.GET_OPPORTUNITIES_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, opportunities: false },
        error: { ...state.error, opportunities: null },
        data: { ...state.data, opportunities: action.payload.opportunities },
      };
    case OpportunitiesAction.GET_OPPORTUNITIES_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, opportunities: false },
        error: { ...state.error, opportunities: action.payload.error },
      };
    case OpportunitiesAction.CREATE_OPPORTUNITY:
      return {
        ...state,
        sending: { ...state.sending, create: true },
      };
    case OpportunitiesAction.CREATE_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        sending: { ...state.sending, create: false },
        error: { ...state.error, create: null },
      };
    case OpportunitiesAction.CREATE_OPPORTUNITY_FAILURE:
      return {
        ...state,
        sending: { ...state.sending, create: false },
        error: { ...state.error, create: action.payload.error },
      };
    case OpportunitiesAction.UPDATE_OPPORTUNITY:
      return {
        ...state,
        sending: { ...state.sending, update: true },
      };
    case OpportunitiesAction.UPDATE_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        sending: { ...state.sending, update: false },
        error: { ...state.error, update: null },
      };
    case OpportunitiesAction.UPDATE_OPPORTUNITY_FAILURE:
      return {
        ...state,
        sending: { ...state.sending, update: false },
        error: { ...state.error, update: action.payload.error },
      };
    case OpportunitiesAction.GET_OPPORTUNITY:
      return {
        ...state,
        loading: { ...state.loading, selectedOpportunity: true },
      };
    case OpportunitiesAction.GET_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, selectedOpportunity: false },
        data: { ...state.data, selectedOpportunity: action.payload.opportunity },
        error: { ...state.error, selectedOpportunity: null },
      };
    case OpportunitiesAction.GET_OPPORTUNITY_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, selectedOpportunity: false },
        error: { ...state.error, selectedOpportunity: action.payload.error },
      };
    case OpportunitiesAction.UPDATE_OPPORTUNITY_STATE:
      return {
        ...state,
        loading: { ...state.loading, stateUpdate: true },
      };
    case OpportunitiesAction.UPDATE_OPPORTUNITY_STATE_SUCCESS:
      const { projectId, stateId } = action.payload;
      const newOpportunities = state.data.opportunities.map((opportunity) => {
        if (opportunity.id === projectId) return { ...opportunity, stateId };
        return opportunity;
      });
      return {
        ...state,
        loading: { ...state.loading, stateUpdate: false },
        data: { ...state.data, opportunities: newOpportunities },
      };
    case OpportunitiesAction.UPDATE_OPPORTUNITY_STATE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, stateUpdate: false },
      };
    default:
      return state;
  }
};
