import React from 'react';
import UserManager from '../UserManager';
import AppLayout from '../../containers/AppLayout';
import { Route, Switch, useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import { ROUTES } from '../../routes/app/routes';
import asyncComponent from '../../util/asyncComponent';

const MainApp = () => {
  const location = useLocation();
  const match = useRouteMatch();
  const history = useHistory();

  if (location.pathname === '/') {
    history.push('/opportunities');
  }

  return (
    <UserManager>
      <AppLayout>
        <div className="gx-main-content-wrapper">
          <Switch>
            {ROUTES.map(({ url, component }) => (
              <Route key={url} path={`${match.url}${url}`} component={component} />
            ))}
            <Route
              key={'/new-opportunity'}
              path={`${match.url}new-opportunity`}
              component={asyncComponent(() => import('../../routes/app/OpportunityDetailPage/OpportunityInfo'))}
            />
            <Route
              key={'/opportunity'}
              path={`${match.url}opportunity`}
              component={asyncComponent(() => import('../../routes/app/OpportunityDetailPage'))}
            />
          </Switch>
        </div>
      </AppLayout>
    </UserManager>
  );
};

export default MainApp;
