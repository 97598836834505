import { AuthState, AuthStatus } from './types';
import { AuthAction, AuthActionTypes } from './actions';
import { SWITCH_LANGUAGE } from 'constants/ActionTypes';

export const initialState: AuthState = {
  error: null,
  loading: {
    signUp: false,
    getSignedUser: false,
  },
  sending: false,
  data: null,
  user: null,
  authStatus: AuthStatus.NOT_AUTHENTICATED,
  googleUserInfo: null,
  emailVerificationStatus: AuthStatus.NOT_AUTHENTICATED,
};

export default (state = initialState, action: AuthActionTypes): AuthState => {
  switch (action.type) {
    case AuthAction.SIGN_IN_WITH_GOOGLE:
    case AuthAction.SIGN_IN:
      return {
        ...state,
        authStatus: AuthStatus.PENDING,
      };
    case AuthAction.SIGN_IN_SUCCESS:
      return {
        ...state,
        error: null,
        data: action.payload.tokenData,
        authStatus: AuthStatus.AUTHENTICATED,
      };
    case AuthAction.SIGN_IN_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        authStatus: AuthStatus.NOT_AUTHENTICATED,
      };
    case AuthAction.REFRESH_TOKEN:
      return {
        ...state,
        authStatus: AuthStatus.REFRESHING,
      };
    case AuthAction.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        error: null,
        data: action.payload.tokenData,
        authStatus: AuthStatus.AUTHENTICATED,
      };
    case AuthAction.REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        authStatus: AuthStatus.NOT_AUTHENTICATED,
      };
    case AuthAction.SIGN_UP:
      return {
        ...state,
        loading: {
          ...state.loading,
          signUp: true,
        },
      };
    case AuthAction.SIGN_UP_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          signUp: false,
        },
        error: null,
      };
    case AuthAction.SIGN_UP_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          signUp: false,
        },
        error: action.payload.error,
      };
    case AuthAction.SAVE_GOOGLE_USER_INFO:
      return {
        ...state,
        googleUserInfo: action.payload.googleUserInfo,
      };
    case AuthAction.GET_SIGNED_USER:
      return {
        ...state,
        loading: {
          ...state.loading,
          getSignedUser: true,
        },
      };
    case AuthAction.GET_SIGNED_USER_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getSignedUser: false,
        },
        error: null,
        user: action.payload.user,
      };
    case AuthAction.GET_SIGNED_USER_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          getSignedUser: false,
        },
        error: action.payload.error,
        user: null,
      };
    case AuthAction.VERIFY_EMAIL:
      return {
        ...state,
        emailVerificationStatus: AuthStatus.PENDING,
      };
    case AuthAction.VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        emailVerificationStatus: AuthStatus.AUTHENTICATED,
      };
    case AuthAction.VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        emailVerificationStatus: AuthStatus.NOT_AUTHENTICATED,
      };
    case AuthAction.UPDATE_USER:
      return {
        ...state,
        sending: true,
      };
    case AuthAction.UPDATE_USER_SUCCESS:
      return {
        ...state,
        sending: false,
      };
    case AuthAction.UPDATE_USER_FAILURE:
      return {
        ...state,
        sending: false,
      };
    case SWITCH_LANGUAGE:
      return {
        ...state,
        sending: true,
      };
    default:
      return state;
  }
};
