import React, { ReactNode, ComponentType } from 'react';
import IntlMessages from 'util/IntlMessages';
import asyncComponent from 'util/asyncComponent';

export type Route = { name: ReactNode; url: string; icon?: string; component: ComponentType };

export const ROUTES: Route[] = [
  {
    url: 'opportunities',
    icon: 'sent',
    name: <IntlMessages id="sidebar.opportunities" />,
    component: asyncComponent(() => import('./OpportunitiesPage')),
  },
  {
    url: 'projects',
    icon: 'product-list',
    name: <IntlMessages id="sidebar.projects" />,
    component: asyncComponent(() => import('./ComingSoonPage')),
  },
  {
    url: 'houses',
    icon: 'home',
    name: <IntlMessages id="sidebar.houses" />,
    component: asyncComponent(() => import('./Houses')),
  },
  {
    url: 'orders',
    icon: 'tag',
    name: <IntlMessages id="sidebar.orders" />,
    component: asyncComponent(() => import('./ComingSoonPage')),
  },
  {
    url: 'invoice',
    icon: 'attachment',
    name: <IntlMessages id="sidebar.invoice" />,
    component: asyncComponent(() => import('./ComingSoonPage')),
  },
  {
    url: 'users',
    icon: 'user',
    name: <IntlMessages id="sidebar.users" />,
    component: asyncComponent(() => import('./ComingSoonPage')),
  },
  {
    url: 'settings',
    icon: 'setting',
    name: <IntlMessages id="sidebar.settings" />,
    component: asyncComponent(() => import('./ComingSoonPage')),
  },
  {
    url: 'materials',
    icon: 'table',
    name: <IntlMessages id="sidebar.materials" />,
    component: asyncComponent(() => import('./ComingSoonPage')),
  },
  {
    url: 'deployment',
    icon: 'setting',
    name: <IntlMessages id="sidebar.deployment" />,
    component: asyncComponent(() => import('./DeploymentManagement')),
  },
];
