const languageData = [
  {
    languageId: 'czech',
    locale: 'cs',
    name: 'Czech',
    icon: 'cz',
  },
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us',
  },
];

export default languageData;
