import React from 'react';
import ReactDOM from 'react-dom';

import Root from './Root';
import { unregister } from './registerServiceWorker';
// Add this import:
import { AppContainer } from 'react-hot-loader';

// Wrap the rendering in a function:
const render = (Component) => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <Component />
    </AppContainer>,
    document.getElementById('root'),
  );
};

// Do this once
unregister();

// Render once
render(Root);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./Root', () => {
    render(Root);
  });
}
