import { AxiosError } from 'axios';
import {
  Floor,
  FloorPostParams,
  FloorPutParams,
  GetFloorsQueryParams,
  GetRoomsQueryParams,
  Room,
  RoomPostParams,
  RoomPutParams,
} from './types';

export enum HouseholdAction {
  GET_ROOMS = 'household/GET_ROOMS',
  GET_ROOMS_SUCCESS = 'household/GET_ROOMS_SUCCESS',
  GET_ROOMS_FAILURE = 'household/GET_ROOMS_FAILURE',
  CREATE_ROOM = 'household/CREATE_ROOM',
  CREATE_ROOM_SUCCESS = 'household/CREATE_ROOM_SUCCESS',
  CREATE_ROOM_FAILURE = 'household/CREATE_ROOM_FAILURE',
  UPDATE_ROOM = 'household/UPDATE_ROOM',
  UPDATE_ROOM_SUCCESS = 'household/UPDATE_ROOM_SUCCESS',
  UPDATE_ROOM_FAILURE = 'household/UPDATE_ROOM_FAILURE',
  DELETE_ROOM = 'household/DELETE_ROOM',
  DELETE_ROOM_SUCCESS = 'household/DELETE_ROOM_SUCCESS',
  DELETE_ROOM_FAILURE = 'household/DELETE_ROOM_FAILURE',
  SELECT_ROOM = 'household/SELECT_ROOM',
  GET_FLOORS = 'household/GET_FLOORS',
  GET_FLOORS_SUCCESS = 'household/GET_FLOORS_SUCCESS',
  GET_FLOORS_FAILURE = 'household/GET_FLOORS_FAILURE',
  CREATE_FLOOR = 'household/CREATE_FLOOR',
  CREATE_FLOOR_SUCCESS = 'household/CREATE_FLOOR_SUCCESS',
  CREATE_FLOOR_FAILURE = 'household/CREATE_FLOOR_FAILURE',
  UPDATE_FLOOR = 'household/UPDATE_FLOOR',
  UPDATE_FLOOR_SUCCESS = 'household/UPDATE_FLOOR_SUCCESS',
  UPDATE_FLOOR_FAILURE = 'household/UPDATE_FLOOR_FAILURE',
  DELETE_FLOOR = 'household/DELETE_FLOOR',
  DELETE_FLOOR_SUCCESS = 'household/DELETE_FLOOR_SUCCESS',
  DELETE_FLOOR_FAILURE = 'household/DELETE_FLOOR_FAILURE',
}

type GetFloors = { type: HouseholdAction.GET_FLOORS; payload: { query: GetFloorsQueryParams } };
export function getFloors(query: GetFloorsQueryParams): GetFloors {
  return {
    type: HouseholdAction.GET_FLOORS,
    payload: { query },
  };
}

type GetFloorsSuccess = { type: HouseholdAction.GET_FLOORS_SUCCESS; payload: { floors: Floor[] } };
export function getFloorsSuccess(floors: Floor[]): GetFloorsSuccess {
  return {
    type: HouseholdAction.GET_FLOORS_SUCCESS,
    payload: { floors },
  };
}

type GetFloorsFailure = {
  type: HouseholdAction.GET_FLOORS_FAILURE;
  payload: { error: AxiosError };
};
export function getFloorsFailure(error: AxiosError): GetFloorsFailure {
  return {
    type: HouseholdAction.GET_FLOORS_FAILURE,
    payload: { error },
  };
}

export type CreateFloor = {
  type: HouseholdAction.CREATE_FLOOR;
  payload: { floor: FloorPostParams };
};
export const createFloor = (floor: FloorPostParams): CreateFloor => ({
  type: HouseholdAction.CREATE_FLOOR,
  payload: { floor },
});

export type CreateFloorSuccess = {
  type: HouseholdAction.CREATE_FLOOR_SUCCESS;
  payload: { floor: Floor };
};
export const createFloorSuccess = (floor: Floor): CreateFloorSuccess => ({
  type: HouseholdAction.CREATE_FLOOR_SUCCESS,
  payload: { floor },
});

export type CreateFloorFailure = {
  type: HouseholdAction.CREATE_FLOOR_FAILURE;
  payload: { error: AxiosError };
};
export const createFloorFailure = (error: AxiosError): CreateFloorFailure => ({
  type: HouseholdAction.CREATE_FLOOR_FAILURE,
  payload: { error },
});

type UpdateFloor = { type: HouseholdAction.UPDATE_FLOOR; payload: { floor: FloorPutParams; floorId: number } };
export function updateFloor(floor: FloorPutParams, floorId: number): UpdateFloor {
  return {
    type: HouseholdAction.UPDATE_FLOOR,
    payload: { floor, floorId },
  };
}

type UpdateFloorSuccess = { type: HouseholdAction.UPDATE_FLOOR_SUCCESS };
export function updateFloorSuccess(): UpdateFloorSuccess {
  return {
    type: HouseholdAction.UPDATE_FLOOR_SUCCESS,
  };
}

type UpdateFloorFailure = {
  type: HouseholdAction.UPDATE_FLOOR_FAILURE;
  payload: { error: AxiosError };
};
export function updateFloorFailure(error: AxiosError): UpdateFloorFailure {
  return {
    type: HouseholdAction.UPDATE_FLOOR_FAILURE,
    payload: { error },
  };
}

type DeleteFloor = { type: HouseholdAction.DELETE_FLOOR; payload: { floorId: number } };
export function deleteFloor(floorId: number): DeleteFloor {
  return {
    type: HouseholdAction.DELETE_FLOOR,
    payload: {
      floorId,
    },
  };
}

type DeleteFloorSuccess = { type: HouseholdAction.DELETE_FLOOR_SUCCESS; payload: { id: number } };
export function deleteFloorSuccess(id: number): DeleteFloorSuccess {
  return {
    type: HouseholdAction.DELETE_FLOOR_SUCCESS,
    payload: { id },
  };
}

type DeleteFloorFailure = {
  type: HouseholdAction.DELETE_FLOOR_FAILURE;
  payload: { error: AxiosError };
};
export function deleteFloorFailure(error: AxiosError): DeleteFloorFailure {
  return {
    type: HouseholdAction.DELETE_FLOOR_FAILURE,
    payload: { error },
  };
}

type SelectRoom = { type: HouseholdAction.SELECT_ROOM; payload: { room: Room | null } };
export const selectRoom = (room: Room | null): SelectRoom => ({
  type: HouseholdAction.SELECT_ROOM,
  payload: { room },
});

type GetRooms = { type: HouseholdAction.GET_ROOMS; payload: { query: GetRoomsQueryParams } };
export function getRooms(query: GetRoomsQueryParams): GetRooms {
  return {
    type: HouseholdAction.GET_ROOMS,
    payload: { query },
  };
}

type GetRoomsSuccess = { type: HouseholdAction.GET_ROOMS_SUCCESS; payload: { rooms: Room[] } };
export function getRoomsSuccess(rooms: Room[]): GetRoomsSuccess {
  return {
    type: HouseholdAction.GET_ROOMS_SUCCESS,
    payload: { rooms },
  };
}

type GetRoomsFailure = {
  type: HouseholdAction.GET_ROOMS_FAILURE;
  payload: { error: AxiosError };
};
export function getRoomsFailure(error: AxiosError): GetRoomsFailure {
  return {
    type: HouseholdAction.GET_ROOMS_FAILURE,
    payload: { error },
  };
}

export type CreateRoom = {
  type: HouseholdAction.CREATE_ROOM;
  payload: { room: RoomPostParams };
};
export const createRoom = (room: RoomPostParams): CreateRoom => ({
  type: HouseholdAction.CREATE_ROOM,
  payload: { room },
});

export type CreateRoomSuccess = {
  type: HouseholdAction.CREATE_ROOM_SUCCESS;
  payload: { room: Room };
};
export const createRoomSuccess = (room: Room): CreateRoomSuccess => ({
  type: HouseholdAction.CREATE_ROOM_SUCCESS,
  payload: { room },
});

export type CreateRoomFailure = {
  type: HouseholdAction.CREATE_ROOM_FAILURE;
  payload: { error: AxiosError };
};
export const createRoomFailure = (error: AxiosError): CreateRoomFailure => ({
  type: HouseholdAction.CREATE_ROOM_FAILURE,
  payload: { error },
});

type UpdateRoom = { type: HouseholdAction.UPDATE_ROOM; payload: { room: RoomPutParams; roomId: number } };
export function updateRoom(room: RoomPutParams, roomId: number): UpdateRoom {
  return {
    type: HouseholdAction.UPDATE_ROOM,
    payload: { room, roomId },
  };
}

type UpdateRoomSuccess = { type: HouseholdAction.UPDATE_ROOM_SUCCESS };
export function updateRoomSuccess(): UpdateRoomSuccess {
  return {
    type: HouseholdAction.UPDATE_ROOM_SUCCESS,
  };
}

type UpdateRoomFailure = { type: HouseholdAction.UPDATE_ROOM_FAILURE; payload: { error: AxiosError } };
export function updateRoomFailure(error: AxiosError): UpdateRoomFailure {
  return {
    type: HouseholdAction.UPDATE_ROOM_FAILURE,
    payload: { error },
  };
}

type DeleteRoom = { type: HouseholdAction.DELETE_ROOM; payload: { roomId: number } };
export function deleteRoom(roomId: number): DeleteRoom {
  return {
    type: HouseholdAction.DELETE_ROOM,
    payload: {
      roomId,
    },
  };
}

type DeleteRoomSuccess = { type: HouseholdAction.DELETE_ROOM_SUCCESS; payload: { id: number } };
export function deleteRoomSuccess(id: number): DeleteRoomSuccess {
  return {
    type: HouseholdAction.DELETE_ROOM_SUCCESS,
    payload: { id },
  };
}

type DeleteRoomFailure = { type: HouseholdAction.DELETE_ROOM_FAILURE; payload: { error: AxiosError } };
export function deleteRoomFailure(error: AxiosError): DeleteRoomFailure {
  return {
    type: HouseholdAction.DELETE_ROOM_FAILURE,
    payload: { error },
  };
}

export type HouseHoldActionTypes =
  | GetRooms
  | GetRoomsSuccess
  | GetRoomsFailure
  | CreateRoom
  | CreateRoomSuccess
  | CreateRoomFailure
  | UpdateRoom
  | UpdateRoomSuccess
  | UpdateRoomFailure
  | DeleteRoom
  | DeleteRoomSuccess
  | DeleteRoomFailure
  | SelectRoom
  | GetFloors
  | GetFloorsSuccess
  | GetFloorsFailure
  | CreateFloor
  | CreateFloorSuccess
  | CreateFloorFailure
  | UpdateFloor
  | UpdateFloorSuccess
  | UpdateFloorFailure
  | DeleteFloor
  | DeleteFloorSuccess
  | DeleteFloorFailure;
