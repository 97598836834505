import { managementServiceApi } from 'util/api';
import { Configuration, CreateConfigurationResponse, GetHardwareResponse, GetPriceResponse } from './types';

export default {
  getConfiguration: (projectId: number): Promise<Configuration> => {
    return managementServiceApi(`/configurations?projectId=${projectId}`, {
      method: 'GET',
    });
  },

  createConfiguration: (projectId: number): Promise<CreateConfigurationResponse> => {
    return managementServiceApi(`/configurations?projectId=${projectId}`, {
      method: 'POST',
    });
  },

  reconfigure: (configurationId: number): Promise<void> =>
    managementServiceApi(`/configurations/${configurationId}/reconfigure`, { method: 'PUT' }),

  getPrice: (configurationId: number): Promise<GetPriceResponse> =>
    managementServiceApi(`/configurations/${configurationId}/priceDecomposition`, { method: 'GET' }),

  getHardware: (configurationId: number): Promise<GetHardwareResponse> =>
    managementServiceApi(`/configurations/${configurationId}/hardwareDecomposition`, { method: 'GET' }),

  sendOrder: (configurationId: number): Promise<void> =>
    managementServiceApi(`/configurations/${configurationId}/order`, { method: 'PUT' }),
};
