import { AnyAction, applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';
import createAppReducer from '../reducers';
import rootSagas from '../sagas';
import refreshTokenMiddleware from './middlewares/refreshTokenMiddleware';
import { AuthAction } from '../modules/auth/actions';
import storage from 'redux-persist/lib/storage';

const createBrowserHistory = require('history').createBrowserHistory;
export const history = createBrowserHistory();

const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [refreshTokenMiddleware, sagaMiddleware, routeMiddleware];

const appReducer = createAppReducer(history);

//TODO Replace any with AppStore after finish writing types
const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === AuthAction.SIGN_OUT || action.type === AuthAction.REFRESH_TOKEN_FAILURE) {
    storage.removeItem('persist:auth');
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const enhancers = applyMiddleware(
  routerMiddleware(history), // for dispatching history actions
  ...middlewares,
);

const store = createStore(
  rootReducer,
  {},
  process.env.NODE_ENV === 'development' ? composeWithDevTools(enhancers) : enhancers,
);

rootSagas.map((saga: any) => sagaMiddleware.run(saga));

const persistor = persistStore(store);

export { store, persistor };
