import { DateTime, Duration } from 'luxon';

const FIVE_MINUTES_IN_MILLISECONDS = 5 * 60 * 1000;

export const createUsableToken = (tokenType: string, token: string) => {
  return `${tokenType} ${token}`;
};

export const createTokenExpirationTimestamp = (tokenDurationInSec: number) => {
  const now = DateTime.local();

  return now.plus(Duration.fromMillis(tokenDurationInSec * 1000)).toMillis();
};

export const isTokenCloseToExpiration = (expirationTimeStamp: number) => {
  const now = DateTime.local();

  return now.plus(Duration.fromMillis(FIVE_MINUTES_IN_MILLISECONDS)).toMillis() > expirationTimeStamp;
};
