import { store } from 'appRedux/store';
import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';
import config from '../config';

interface Options {
  body?: any;
  returnRaw?: boolean;
}

interface ManagementServiceApiOptions extends Options {
  withoutAuthorization?: boolean;
}

export const identityServerApi = (
  url: string,
  { body, headers, returnRaw, ...options }: AxiosRequestConfig & Options,
  params?: any,
) => {
  return Axios({
    ...options,
    baseURL: config.identityServer.url,
    url,
    data: body,
    headers: {
      ...headers,
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params,
    paramsSerializer: (params: any) => qs.stringify(params, { indices: false }),
  }).then((response: AxiosResponse) => (returnRaw ? response : response.data));
};

export const getServiceApi = (
  baseURL: string,
  url: string,
  { body, headers, returnRaw, withoutAuthorization, ...options }: AxiosRequestConfig & ManagementServiceApiOptions,
  params?: any,
) => {
  const getAccessToken = () => {
    const token = store.getState().auth.data?.access_token;
    if (!token) {
      throw Error('Can not get access token');
    }
    return token;
  };

  return Axios({
    ...options,
    baseURL,
    url,
    data: body,
    headers: {
      ...headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(!withoutAuthorization && { authorization: getAccessToken() }),
    },
    params,
    paramsSerializer: (params: any) => qs.stringify(params, { indices: false }),
  }).then((response: AxiosResponse) => (returnRaw ? response : response.data));
};

export const managementServiceApi = (
  url: string,
  options: AxiosRequestConfig & ManagementServiceApiOptions,
  params?: any,
) => {
  if (!config.managementServiceUrl) {
    throw new Error('There have to be set management service URL!');
  }
  return getServiceApi(config.managementServiceUrl, url, options, params);
};

export const deploymentServiceApi = (
  url: string,
  options: AxiosRequestConfig & ManagementServiceApiOptions,
  params?: any,
) => {
  if (!config.deploymentServiceUrl) {
    throw new Error('There have to be set deployment service URL!');
  }
  return getServiceApi(config.deploymentServiceUrl, url, options, params);
};

export const gatewayServiceApi = (
  url: string,
  options: AxiosRequestConfig & ManagementServiceApiOptions,
  params?: any,
) => {
  if (!config.gatewayServiceUrl) {
    throw new Error('There have to be set gateway service URL!');
  }
  return getServiceApi(config.gatewayServiceUrl, url, options, params);
};
