import { Avatar, Popover } from 'antd';
import { selectSignedUser } from 'appRedux/modules/auth/selectors';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from '../../appRedux/modules/auth/actions';
import IntlMessages from '../../util/IntlMessages';

const UserProfile = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectSignedUser);

  const handleSignOut = () => {
    dispatch(signOut());
  };

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={handleSignOut}>
        <IntlMessages id="app.userAuth.signOut" />
      </li>
    </ul>
  );

  return (
    <div className="gx-flex-row gx-align-items-center gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <Avatar
          src={require('assets/images/placeholder.jpg')}
          className="gx-size-40 gx-pointer gx-mr-3"
          alt="profile picture"
        />
        <span className="gx-avatar-name">
          {user && `${user.firstName} ${user.lastName}`}
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </span>
      </Popover>
    </div>
  );
};

export default UserProfile;
