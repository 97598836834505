import { Country } from './types';
import { AxiosError } from 'axios';

export enum CountriesAction {
  GET_COUNTRIES = 'countries/GET_COUNTRIES',
  GET_COUNTRIES_SUCCESS = 'countries/GET_COUNTRIES_SUCCESS',
  GET_COUNTRIES_FAILURE = 'countries/GET_COUNTRIES_FAILURE',
}

export type GetCountries = { type: CountriesAction.GET_COUNTRIES };
export const getCountries = (): GetCountries => {
  return {
    type: CountriesAction.GET_COUNTRIES,
  };
};

export type GetCountriesSuccess = { type: CountriesAction.GET_COUNTRIES_SUCCESS; payload: { countries: Country[] } };
export const getCountriesSuccess = (countries: Country[]): GetCountriesSuccess => {
  return {
    type: CountriesAction.GET_COUNTRIES_SUCCESS,
    payload: { countries },
  };
};

export type GetCountriesFailure = { type: CountriesAction.GET_COUNTRIES_FAILURE; payload: { error: AxiosError } };
export const getCountriesFailure = (error: AxiosError): GetCountriesFailure => {
  return {
    type: CountriesAction.GET_COUNTRIES_FAILURE,
    payload: { error },
  };
};

export type CountriesActionTypes = GetCountries | GetCountriesSuccess | GetCountriesFailure;
