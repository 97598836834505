import { AxiosError } from 'axios';

export interface OrganizationsState {
  createOrganizationStatus: OrganizationCreateStatus;
  error: AxiosError | null;
}

export interface OrganizationPostBody {
  name: string;
  tin: string;
  vatId: string;
  email?: string;
  website?: string;
  phone?: string;
  address: {
    street: string;
    city: string;
    area?: string;
    zipCode: string;
    countryId: number;
  };
}

export interface CreateOrganizationResponse {
  id: number;
}

export enum OrganizationCreateStatus {
  CREATED,
  PENDING,
  NOT_CREATED,
}
