import { managementServiceApi } from '../../../util/api';
import { GetOpportunitiesResponse, Opportunity, OpportunityPost, OpportunityPut } from './types';

export default {
  getOpportunities: (organizationId: number, offset: number, limit: number): Promise<GetOpportunitiesResponse> => {
    return managementServiceApi(
      '/projects',
      {
        method: 'GET',
      },
      { organizationId, offset, limit },
    );
  },

  createOpportunity: (opportunity: OpportunityPost): Promise<number> => {
    return managementServiceApi('/projects', {
      method: 'POST',
      body: opportunity,
    });
  },

  updateOpportunity: (id: number, opportunity: OpportunityPut): Promise<void> => {
    return managementServiceApi(`/projects/${id}`, {
      method: 'PUT',
      body: opportunity,
    });
  },

  getOpportunityById: (id: number): Promise<Opportunity> => {
    return managementServiceApi(`projects/${id}`, {
      method: 'GET',
    });
  },

  updateOpportunityState: (projectId: number, stateId: number): Promise<void> => {
    return managementServiceApi(`projects/${projectId}/state`, {
      method: 'PUT',
      body: { stateId },
    });
  },
};
