import antdCs from 'antd/lib/locale-provider/cs_CZ';
import csMessages from '../locales/cs_CZ.json';

const CsLang = {
  messages: {
    ...csMessages,
  },
  antd: antdCs,
  locale: 'cs-CZ',
};
export default CsLang;
