import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Settings from './Settings';
import Common from './Common';
import { connectRouter } from 'connected-react-router';
import { reducer as auth } from '../modules/auth';
import { reducer as countries } from '../modules/countries';
import { reducer as organizations } from '../modules/organizations';
import { reducer as opportunities } from '../modules/opportunities';
import { reducer as configuration } from '../modules/configurations';
import { reducer as household } from '../modules/household';
import { reducer as devices } from '../modules/devices';

const settingsPersistConfig = {
  key: 'settings',
  storage,
  whitelist: ['settings'],
};

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['data'],
};

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: persistReducer(settingsPersistConfig, Settings),
    commonData: Common,
    auth: persistReducer(authPersistConfig, auth),
    countries,
    organizations,
    opportunities,
    configuration,
    household,
    devices,
  });
