import { SignUpLanguage } from 'constants/constants';

export const mapLanguageToSignUpLanguageId = (language: string) => {
  const shortLanguage = language.split('-')[0];

  switch (shortLanguage) {
    case 'cs':
      return SignUpLanguage.CS;
    default:
      return SignUpLanguage.EN;
  }
};
