import React from 'react';
import { NotificationManager } from 'react-notifications';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import IntlMessages from 'util/IntlMessages';
import * as actions from './actions';
import apiService from './apiService';
import { CreateConfigurationResponse, GetConfigurationsResponse, GetHardwareResponse, GetPriceResponse } from './types';

function* watchGetConfiguration() {
  yield takeLatest(actions.ConfigurationAction.GET_CONFIGURATIONS, function* handle(
    action: ReturnType<typeof actions.getConfiguration>,
  ) {
    const { projectId } = action.payload;
    try {
      const configuration: GetConfigurationsResponse = yield call(apiService.getConfiguration, projectId);
      yield put(actions.getConfigurationSuccess(configuration));
    } catch (error) {
      yield put(actions.getConfigurationFailure(error));
      NotificationManager.error(<IntlMessages id="app.opportunity.calculator.getConfigurationError" />);
    }
  });
}

function* watchCreateConfiguration() {
  yield takeLatest(actions.ConfigurationAction.CREATE_CONFIGURATION, function* handle(
    action: ReturnType<typeof actions.createConfiguration>,
  ) {
    const { projectId } = action.payload;
    try {
      const { id }: CreateConfigurationResponse = yield call(apiService.createConfiguration, projectId);
      yield put(actions.createConfigurationSuccess({ id, projectId, ordered: false }));
    } catch (error) {
      yield put(actions.createConfigurationFailure(error));
      NotificationManager.error(<IntlMessages id="app.opportunity.calculator.createConfigurationError" />);
    }
  });
}

function* watchReconfigure() {
  yield takeLatest(actions.ConfigurationAction.RECONFIGURE, function* handle(
    action: ReturnType<typeof actions.reconfigure>,
  ) {
    const { configurationId } = action.payload;
    try {
      yield call(apiService.reconfigure, configurationId);
      yield put(actions.reconfigureSuccess());
    } catch (error) {
      yield put(actions.reconfigureFailure(error));
    }
  });
}

function* watchGetPrice() {
  yield takeLatest(actions.ConfigurationAction.GET_PRICE, function* handle(
    action: ReturnType<typeof actions.getPrice>,
  ) {
    try {
      const response: GetPriceResponse = yield call(apiService.getPrice, action.payload.configurationId);
      yield put(actions.getPriceSuccess(response));
    } catch (error) {
      yield put(actions.getPriceFailure(error));
    }
  });
}

function* watchGetHardware() {
  yield takeLatest(actions.ConfigurationAction.GET_HARDWARE, function* handle(
    action: ReturnType<typeof actions.getHardware>,
  ) {
    try {
      const response: GetHardwareResponse = yield call(apiService.getHardware, action.payload.configurationId);
      yield put(actions.getHardwareSuccess(response));
    } catch (error) {
      yield put(actions.getHardwareFailure(error));
    }
  });
}

function* watchSendOrder() {
  yield takeLatest(actions.ConfigurationAction.SEND_ORDER, function* handle(
    action: ReturnType<typeof actions.sendOrder>,
  ) {
    try {
      yield call(apiService.sendOrder, action.payload.configurationId);
      yield put(actions.sendOrderSuccess());
    } catch (error) {
      yield put(actions.sendOrderFailure(error));
      NotificationManager.error(<IntlMessages id="app.opportunity.calculator.offer.orderError" />);
    }
  });
}

export default function* configurationSaga() {
  yield fork(watchGetConfiguration);
  yield fork(watchCreateConfiguration);
  yield fork(watchReconfigure);
  yield fork(watchGetPrice);
  yield fork(watchGetHardware);
  yield fork(watchSendOrder);
}
