import { AxiosError } from 'axios';
import { Configuration, GetHardwareResponse, GetPriceResponse } from './types';

export enum ConfigurationAction {
  GET_CONFIGURATIONS = 'configuration/GET_CONFIGURATIONS',
  GET_CONFIGURATIONS_SUCCESS = 'configuration/GET_CONFIGURATIONS_SUCCESS',
  GET_CONFIGURATIONS_FAILURE = 'configuration/GET_CONFIGURATIONS_FAILURE',

  CREATE_CONFIGURATION = 'configuration/CREATE_CONFIGURATION',
  CREATE_CONFIGURATION_SUCCESS = 'configuration/CREATE_CONFIGURATION_SUCCESS',
  CREATE_CONFIGURATION_FAILURE = 'configuration/CREATE_CONFIGURATION_FAILURE',

  RECONFIGURE = 'configuration/RECONFIGURE',
  RECONFIGURE_SUCCESS = 'configuration/RECONFIGURE_SUCCESS',
  RECONFIGURE_FAILURE = 'configuration/RECONFIGURE_FAILURE',

  GET_PRICE = 'configuration/GET_PRICE',
  GET_PRICE_SUCCESS = 'configuration/GET_PRICE_SUCCESS',
  GET_PRICE_FAILURE = 'configuration/GET_PRICE_FAILURE',

  GET_HARDWARE = 'configuration/GET_HARDWARE',
  GET_HARDWARE_SUCCESS = 'configuration/GET_HARDWARE_SUCCESS',
  GET_HARDWARE_FAILURE = 'configuration/GET_HARDWARE_FAILURE',

  SEND_ORDER = 'configuration/SEND_ORDER',
  SEND_ORDER_SUCCESS = 'configuration/SEND_ORDER_SUCCESS',
  SEND_ORDER_FAILURE = 'configuration/SEND_ORDER_FAILURE',

  RESET_CONFIGURATIONS = 'configuration/RESET_CONFIGURATIONS',
}

type GetConfiguration = { type: ConfigurationAction.GET_CONFIGURATIONS; payload: { projectId: number } };
export const getConfiguration = (projectId: number): GetConfiguration => ({
  type: ConfigurationAction.GET_CONFIGURATIONS,
  payload: { projectId },
});

type GetConfigurationSuccess = {
  type: ConfigurationAction.GET_CONFIGURATIONS_SUCCESS;
  payload: { configurations: Configuration[] };
};
export const getConfigurationSuccess = (configurations: Configuration[]): GetConfigurationSuccess => ({
  type: ConfigurationAction.GET_CONFIGURATIONS_SUCCESS,
  payload: { configurations },
});

type GetConfigurationFailure = { type: ConfigurationAction.GET_CONFIGURATIONS_FAILURE; payload: { error: AxiosError } };
export const getConfigurationFailure = (error: AxiosError): GetConfigurationFailure => ({
  type: ConfigurationAction.GET_CONFIGURATIONS_FAILURE,
  payload: { error },
});

type CreateConfiguration = {
  type: ConfigurationAction.CREATE_CONFIGURATION;
  payload: { projectId: number; originalConfigurationId: number | null };
};
export const createConfiguration = (
  projectId: number,
  originalConfigurationId: number | null = null,
): CreateConfiguration => ({
  type: ConfigurationAction.CREATE_CONFIGURATION,
  payload: { projectId, originalConfigurationId },
});

type CreateConfigurationSuccess = {
  type: ConfigurationAction.CREATE_CONFIGURATION_SUCCESS;
  payload: { configuration: Configuration };
};
export const createConfigurationSuccess = (configuration: Configuration): CreateConfigurationSuccess => ({
  type: ConfigurationAction.CREATE_CONFIGURATION_SUCCESS,
  payload: { configuration },
});

type CreateConfigurationFailure = {
  type: ConfigurationAction.CREATE_CONFIGURATION_FAILURE;
  payload: { error: AxiosError };
};
export const createConfigurationFailure = (error: AxiosError): CreateConfigurationFailure => ({
  type: ConfigurationAction.CREATE_CONFIGURATION_FAILURE,
  payload: { error },
});

type Reconfigure = {
  type: ConfigurationAction.RECONFIGURE;
  payload: { configurationId: number };
};
export const reconfigure = (configurationId: number): Reconfigure => ({
  type: ConfigurationAction.RECONFIGURE,
  payload: { configurationId },
});

type ReconfigureSuccess = {
  type: ConfigurationAction.RECONFIGURE_SUCCESS;
};
export const reconfigureSuccess = (): ReconfigureSuccess => ({
  type: ConfigurationAction.RECONFIGURE_SUCCESS,
});

type ReconfigureFailure = {
  type: ConfigurationAction.RECONFIGURE_FAILURE;
  payload: { error: AxiosError };
};
export const reconfigureFailure = (error: AxiosError): ReconfigureFailure => ({
  type: ConfigurationAction.RECONFIGURE_FAILURE,
  payload: { error },
});

type GetPrice = { type: ConfigurationAction.GET_PRICE; payload: { configurationId: number } };
export const getPrice = (configurationId: number): GetPrice => ({
  type: ConfigurationAction.GET_PRICE,
  payload: { configurationId },
});

type GetPriceSuccess = { type: ConfigurationAction.GET_PRICE_SUCCESS; payload: GetPriceResponse };
export const getPriceSuccess = (response: GetPriceResponse): GetPriceSuccess => ({
  type: ConfigurationAction.GET_PRICE_SUCCESS,
  payload: response,
});

type GetPriceFailure = { type: ConfigurationAction.GET_PRICE_FAILURE; payload: { error: AxiosError } };
export const getPriceFailure = (error: AxiosError): GetPriceFailure => ({
  type: ConfigurationAction.GET_PRICE_FAILURE,
  payload: { error },
});

type GetHardware = { type: ConfigurationAction.GET_HARDWARE; payload: { configurationId: number } };
export const getHardware = (configurationId: number): GetHardware => ({
  type: ConfigurationAction.GET_HARDWARE,
  payload: { configurationId },
});

type GetHardwareSuccess = { type: ConfigurationAction.GET_HARDWARE_SUCCESS; payload: GetHardwareResponse };
export const getHardwareSuccess = (response: GetHardwareResponse): GetHardwareSuccess => ({
  type: ConfigurationAction.GET_HARDWARE_SUCCESS,
  payload: response,
});

type GetHardwareFailure = { type: ConfigurationAction.GET_HARDWARE_FAILURE; payload: { error: AxiosError } };
export const getHardwareFailure = (error: AxiosError): GetHardwareFailure => ({
  type: ConfigurationAction.GET_HARDWARE_FAILURE,
  payload: { error },
});

type SendOrder = { type: ConfigurationAction.SEND_ORDER; payload: { configurationId: number } };
export const sendOrder = (configurationId: number): SendOrder => ({
  type: ConfigurationAction.SEND_ORDER,
  payload: { configurationId },
});
type SendOrderSuccess = { type: ConfigurationAction.SEND_ORDER_SUCCESS };
export const sendOrderSuccess = (): SendOrderSuccess => ({
  type: ConfigurationAction.SEND_ORDER_SUCCESS,
});
type SendOrderFailure = { type: ConfigurationAction.SEND_ORDER_FAILURE; payload: { error: AxiosError } };
export const sendOrderFailure = (error: AxiosError): SendOrderFailure => ({
  type: ConfigurationAction.SEND_ORDER_FAILURE,
  payload: { error },
});

type ResetConfigurations = { type: ConfigurationAction.RESET_CONFIGURATIONS };
export const resetConfigurations = () => ({
  type: ConfigurationAction.RESET_CONFIGURATIONS,
});

export type ConfigurationActionTypes =
  | GetConfiguration
  | GetConfigurationSuccess
  | GetConfigurationFailure
  | CreateConfiguration
  | CreateConfigurationSuccess
  | CreateConfigurationFailure
  | Reconfigure
  | ReconfigureSuccess
  | ReconfigureFailure
  | GetPrice
  | GetPriceSuccess
  | GetPriceFailure
  | GetHardware
  | GetHardwareSuccess
  | GetHardwareFailure
  | SendOrder
  | SendOrderSuccess
  | SendOrderFailure
  | ResetConfigurations;
