import React, { useEffect } from 'react';
import { Divider, Space } from 'antd';
import { useHistory } from 'react-router-dom';
import IntlMessages from '../../util/IntlMessages';
import { useSelector } from 'react-redux';
import { selectAuthTokenData } from '../../appRedux/modules/auth/selectors';
import SignInForm from './SignInForm';
import SignInWithGoogle from './SignInWithGoogle';

const logo = require('../../assets/images/homeqest-logo.svg');

export interface SignInFormData {
  username: string;
  password: string;
}

const SignIn = () => {
  const history = useHistory();
  const tokenData = useSelector(selectAuthTokenData);

  useEffect(() => {
    if (tokenData) {
      history.push('/');
    }
  }, [tokenData?.access_token]);

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-logo-wrap">
          <img src={logo} alt="HomeQest" className="gx-app-login-logo" />
        </div>
        <Space direction="vertical" size="large" style={{ width: 400 }}>
          <SignInForm />
          <Divider>
            <IntlMessages id="app.userAuth.or" />
          </Divider>
          <SignInWithGoogle />
        </Space>
      </div>
    </div>
  );
};

export default SignIn;
