import { identityServerApi, managementServiceApi } from '../../../util/api';
import {
  CreateUserPostParams,
  GetSignedUserResponse,
  RefreshTokenResponse,
  SignInResponse,
  SignUpPostParams,
  UserPutParams,
} from './types';

export default {
  signIn: (data: URLSearchParams): Promise<SignInResponse> => {
    return identityServerApi('/connect/token', {
      method: 'POST',
      body: data,
    });
  },
  signUp: (data: SignUpPostParams): Promise<void> => {
    return managementServiceApi('/users', {
      method: 'POST',
      body: data,
      withoutAuthorization: true,
    });
  },
  createUser: (data: CreateUserPostParams): Promise<void> => {
    return managementServiceApi('/my/user', {
      method: 'POST',
      body: data,
    });
  },
  updateUser: (data: UserPutParams, id: number): Promise<void> => {
    return managementServiceApi(`users/${id}`, {
      method: 'PUT',
      body: data,
    });
  },
  refreshToken: (data: FormData): Promise<RefreshTokenResponse> => {
    return identityServerApi('/connect/token', {
      method: 'POST',
      body: data,
    });
  },
  getSignedUser: (): Promise<GetSignedUserResponse> => {
    return managementServiceApi('/my/user', {
      method: 'GET',
    });
  },
  verifyEmail: (email: string, token: string): Promise<void> => {
    return managementServiceApi(`/users/${email}/verification?token=${token}`, {
      method: 'GET',
      withoutAuthorization: true,
    });
  },
};
