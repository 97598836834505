import { managementServiceApi } from '../../../util/api';
import { CreateOrganizationResponse, OrganizationPostBody } from './types';

export default {
  createOrganization: (data: OrganizationPostBody): Promise<CreateOrganizationResponse> => {
    return managementServiceApi('/organizations', {
      method: 'POST',
      body: data,
    });
  },
};
