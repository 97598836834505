import { CreateUserPostParams, GoogleProfileObj, SignedUser, TokenData, UserPutParams } from './types';
import { SignInFormData } from '../../../routes/SignIn';
import { AxiosError } from 'axios';
import { SignUpFormData } from '../../../routes/SignUp';
import { GoogleLoginResponse } from 'react-google-login';
import { SWITCH_LANGUAGE } from 'constants/ActionTypes';

export enum AuthAction {
  SIGN_IN_WITH_GOOGLE = 'auth/SIGN_IN_WITH_GOOGLE',
  SIGN_IN = 'auth/SIGN_IN',
  SIGN_IN_SUCCESS = 'auth/SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE = 'auth/SIGN_IN_FAILURE',
  SIGN_UP = 'auth/SIGN_UP',
  SIGN_UP_SUCCESS = 'auth/SIGN_UP_SUCCESS',
  SIGN_UP_FAILURE = 'auth/SIGN_UP_FAILURE',
  SAVE_GOOGLE_USER_INFO = 'auth/SAVE_GOOGLE_USER_INFO',
  CREATE_USER = 'auth/CREATE_USER',
  CREATE_USER_SUCCESS = 'auth/CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE = 'auth/CREATE_USER_FAILURE',
  REFRESH_TOKEN = 'auth/REFRESH_TOKEN',
  REFRESH_TOKEN_SUCCESS = 'auth/REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_FAILURE = 'auth/REFRESH_TOKEN_FAILURE',
  SIGN_OUT = 'auth/SIGN_OUT',
  GET_SIGNED_USER = 'auth/GET_SIGNED_USER',
  GET_SIGNED_USER_SUCCESS = 'auth/GET_SIGNED_USER_SUCCESS',
  GET_SIGNED_USER_FAILURE = 'auth/GET_SIGNED_USER_FAILURE',
  VERIFY_EMAIL = 'auth/VERIFY_EMAIL',
  VERIFY_EMAIL_SUCCESS = 'auth/VERIFY_EMAIL_SUCCESS',
  VERIFY_EMAIL_FAILURE = 'auth/VERIFY_EMAIL_FAILURE',
  UPDATE_USER = 'auth/UPDATE_USER',
  UPDATE_USER_SUCCESS = 'auth/UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE = 'auth/UPDATE_USER_FAILURE',
}

type SignInWithGoogle = { type: AuthAction.SIGN_IN_WITH_GOOGLE; payload: { response: GoogleLoginResponse } };
export const signInWithGoogle = (response: GoogleLoginResponse): SignInWithGoogle => ({
  type: AuthAction.SIGN_IN_WITH_GOOGLE,
  payload: { response },
});

type SignIn = {
  type: AuthAction.SIGN_IN;
  payload: { values: SignInFormData };
};
export const signIn = (values: SignInFormData): SignIn => {
  return {
    type: AuthAction.SIGN_IN,
    payload: { values },
  };
};

type SignInSuccess = { type: AuthAction.SIGN_IN_SUCCESS; payload: { tokenData: TokenData } };
export const signInSuccess = (tokenData: TokenData): SignInSuccess => {
  return {
    type: AuthAction.SIGN_IN_SUCCESS,
    payload: { tokenData },
  };
};

type SignInFailure = { type: AuthAction.SIGN_IN_FAILURE; payload: { error: AxiosError } };
export const signInFailure = (error: AxiosError): SignInFailure => {
  return {
    type: AuthAction.SIGN_IN_FAILURE,
    payload: { error },
  };
};

type SignUp = {
  type: AuthAction.SIGN_UP;
  payload: {
    values: Omit<SignUpFormData, 'confirmPassword'>;
    signInOnSuccess?: boolean;
  };
};
export const signUp = (values: Omit<SignUpFormData, 'confirmPassword'>, signInOnSuccess?: boolean): SignUp => {
  return {
    type: AuthAction.SIGN_UP,
    payload: { values, signInOnSuccess },
  };
};

type SignUpSuccess = { type: AuthAction.SIGN_UP_SUCCESS };
export const signUpSuccess = (): SignUpSuccess => {
  return {
    type: AuthAction.SIGN_UP_SUCCESS,
  };
};

type SignUpFailure = { type: AuthAction.SIGN_UP_FAILURE; payload: { error: AxiosError } };
export const signUpFailure = (error: AxiosError): SignUpFailure => {
  return {
    type: AuthAction.SIGN_UP_FAILURE,
    payload: { error },
  };
};

type SaveGoogleUserInfo = { type: AuthAction.SAVE_GOOGLE_USER_INFO; payload: { googleUserInfo: GoogleProfileObj } };
export const saveGoogleUserInfo = (googleUserInfo: GoogleProfileObj): SaveGoogleUserInfo => ({
  type: AuthAction.SAVE_GOOGLE_USER_INFO,
  payload: { googleUserInfo },
});

type CreateUser = { type: AuthAction.CREATE_USER; payload: { data: CreateUserPostParams } };
export const createUser = (data: CreateUserPostParams): CreateUser => ({
  type: AuthAction.CREATE_USER,
  payload: { data },
});

type CreateUserSuccess = { type: AuthAction.CREATE_USER_SUCCESS };
export const createUserSuccess = (): CreateUserSuccess => ({
  type: AuthAction.CREATE_USER_SUCCESS,
});

type CreateUserFailure = { type: AuthAction.CREATE_USER_FAILURE; payload: { error: AxiosError } };
export const createUserFailure = (error: AxiosError): CreateUserFailure => ({
  type: AuthAction.CREATE_USER_FAILURE,
  payload: { error },
});

type RefreshToken = { type: AuthAction.REFRESH_TOKEN };
export const refreshToken = (): RefreshToken => {
  return {
    type: AuthAction.REFRESH_TOKEN,
  };
};

type RefreshTokenSuccess = { type: AuthAction.REFRESH_TOKEN_SUCCESS; payload: { tokenData: TokenData } };
export const refreshTokenSuccess = (tokenData: TokenData): RefreshTokenSuccess => {
  return {
    type: AuthAction.REFRESH_TOKEN_SUCCESS,
    payload: { tokenData },
  };
};

type RefreshTokenFailure = { type: AuthAction.REFRESH_TOKEN_FAILURE; payload: { error: AxiosError } };
export const refreshTokenFailure = (error: AxiosError): RefreshTokenFailure => {
  return {
    type: AuthAction.REFRESH_TOKEN_FAILURE,
    payload: { error },
  };
};

type SignOut = { type: AuthAction.SIGN_OUT };
export const signOut = (): SignOut => {
  return {
    type: AuthAction.SIGN_OUT,
  };
};

type GetSignedUser = { type: AuthAction.GET_SIGNED_USER };
export const getSignedUser = (): GetSignedUser => {
  return {
    type: AuthAction.GET_SIGNED_USER,
  };
};

type GetSignedUserSuccess = { type: AuthAction.GET_SIGNED_USER_SUCCESS; payload: { user: SignedUser } };
export const getSignedUserSuccess = (user: SignedUser): GetSignedUserSuccess => {
  return {
    type: AuthAction.GET_SIGNED_USER_SUCCESS,
    payload: { user },
  };
};

type GetSignedUserFailure = { type: AuthAction.GET_SIGNED_USER_FAILURE; payload: { error: AxiosError } };
export const getSignedUserFailure = (error: AxiosError): GetSignedUserFailure => {
  return {
    type: AuthAction.GET_SIGNED_USER_FAILURE,
    payload: { error },
  };
};

type VerifyEmail = { type: AuthAction.VERIFY_EMAIL; payload: { email: string; token: string } };
export const verifyEmail = (email: string, token: string): VerifyEmail => ({
  type: AuthAction.VERIFY_EMAIL,
  payload: { email, token },
});

type VerifyEmailSuccess = { type: AuthAction.VERIFY_EMAIL_SUCCESS };
export const verifyEmailSuccess = (): VerifyEmailSuccess => ({
  type: AuthAction.VERIFY_EMAIL_SUCCESS,
});

type VerifyEmailFailure = { type: AuthAction.VERIFY_EMAIL_FAILURE };
export const verifyEmailFailure = (): VerifyEmailFailure => ({
  type: AuthAction.VERIFY_EMAIL_FAILURE,
});

type UpdateUser = { type: AuthAction.UPDATE_USER; payload: { user: UserPutParams } };
export const updateUser = (user: UserPutParams): UpdateUser => ({
  type: AuthAction.UPDATE_USER,
  payload: { user },
});

type UpdateUserSuccess = { type: AuthAction.UPDATE_USER_SUCCESS };
export const updateUserSuccess = (): UpdateUserSuccess => ({
  type: AuthAction.UPDATE_USER_SUCCESS,
});

type UpdateUserFailure = { type: AuthAction.UPDATE_USER_FAILURE; payload: { error: AxiosError } };
export const updateUserFailure = (error: AxiosError): UpdateUserFailure => ({
  type: AuthAction.UPDATE_USER_FAILURE,
  payload: { error },
});

type SwitchLanguage = { type: typeof SWITCH_LANGUAGE; locale: string };

export type AuthActionTypes =
  | SignInWithGoogle
  | SignIn
  | SignInSuccess
  | SignInFailure
  | SignUp
  | SignUpSuccess
  | SignUpFailure
  | SaveGoogleUserInfo
  | CreateUser
  | CreateUserSuccess
  | CreateUserFailure
  | RefreshToken
  | RefreshTokenSuccess
  | RefreshTokenFailure
  | SignOut
  | GetSignedUser
  | GetSignedUserSuccess
  | GetSignedUserFailure
  | VerifyEmail
  | VerifyEmailSuccess
  | VerifyEmailFailure
  | UpdateUser
  | UpdateUserSuccess
  | UpdateUserFailure
  | SwitchLanguage;
