import React, { FC } from 'react';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { SettingsState } from '../../appRedux/types';
import AppLocale from '../../lngProvider';

const LocaleLayer: FC = ({ children }) => {
  const locale = useSelector(({ settings }: SettingsState) => settings.locale);
  const currentAppLocale = AppLocale[locale.locale];

  return (
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd}>{children}</ConfigProvider>
    </IntlProvider>
  );
};

export default LocaleLayer;
