import React, { useEffect, useRef } from 'react';
import { Button, Space } from 'antd';
import { Form, Input } from 'formik-antd';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import IntlMessages from '../../util/IntlMessages';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { signUp } from '../../appRedux/modules/auth/actions';
import config from '../../config';
import { selectAuthStatus, selectAuthTokenData, selectSignUpLoading } from '../../appRedux/modules/auth/selectors';
import { AuthStatus } from 'appRedux/modules/auth/types';

export interface SignUpFormData {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
}

const SignUp = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const formikRef = useRef<FormikProps<SignUpFormData>>(null);

  const tokenData = useSelector(selectAuthTokenData);
  const signUpLoading = useSelector(selectSignUpLoading);
  const signInLoading = useSelector(selectAuthStatus);

  const areInputsDisabled = signUpLoading;

  useEffect(() => {
    if (tokenData && signInLoading !== AuthStatus.PENDING) {
      history.push('/');
    }
  }, [tokenData?.access_token, signInLoading]);

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(intl.formatMessage({ id: 'appModule.invalidEmail' }))
      .required(intl.formatMessage({ id: 'appModule.inputRequired' })),
    firstName: yup
      .string()
      .min(2, intl.formatMessage({ id: 'appModule.shortValue' }))
      .max(50, intl.formatMessage({ id: 'appModule.longValue' }))
      .required(intl.formatMessage({ id: 'appModule.inputRequired' })),
    lastName: yup
      .string()
      .min(2, intl.formatMessage({ id: 'appModule.shortValue' }))
      .max(50, intl.formatMessage({ id: 'appModule.longValue' }))
      .required(intl.formatMessage({ id: 'appModule.inputRequired' })),
    password: yup
      .string()
      .min(8, intl.formatMessage({ id: 'appModule.shortValue' }))
      .max(100, intl.formatMessage({ id: 'appModule.longValue' }))
      .matches(/(.*[A-Z].*)/, intl.formatMessage({ id: 'appModule.requiredCharacters' }))
      .matches(/(.*[a-z].*)/, intl.formatMessage({ id: 'appModule.requiredCharacters' }))
      .matches(/(.*\d.*)/, intl.formatMessage({ id: 'appModule.requiredCharacters' }))
      .matches(/(.*[-+_!@#$%^&*.,?].*)/, intl.formatMessage({ id: 'appModule.requiredCharacters' }))
      .required(intl.formatMessage({ id: 'appModule.inputRequired' })),
    confirmPassword: yup.string().when('password', {
      is: (val) => val?.length,
      then: yup.string().oneOf([yup.ref('password')], intl.formatMessage({ id: 'appModule.confirmPasswordError' })),
    }),
  });

  const handleSubmit = (values: SignUpFormData) => {
    const { confirmPassword, ...valuesWithoutConfirmPassword } = values;
    dispatch(signUp(valuesWithoutConfirmPassword, true));
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <div className="gx-app-login-header">
            <h2 className="gx-app-login-title">
              <IntlMessages id="app.userAuth.signUpTitle" />
            </h2>
          </div>
          <Formik
            initialValues={{ email: '', firstName: '', lastName: '', password: '', confirmPassword: '' }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnBlur
            innerRef={formikRef}
          >
            {({ errors, touched, values }) => (
              <Form className="gx-login-form">
                <Space direction="vertical" size={30}>
                  <div className="input-group">
                    <label
                      htmlFor="sign-up-form-email"
                      className={`input-label${touched.email || values.email ? ' input-label-active' : ''}`}
                    >
                      <IntlMessages id="appModule.email" />
                    </label>
                    <Input
                      name="email"
                      id="sign-up-form-email"
                      disabled={areInputsDisabled}
                      className={errors.email && touched.email ? 'input-error' : ''}
                    />
                    {errors.email && touched.email && <div className="input-error-label">{errors.email}</div>}
                  </div>
                  <div className="input-group">
                    <label
                      htmlFor="sign-up-form-first-name"
                      className={`input-label${touched.firstName || values.firstName ? ' input-label-active' : ''}`}
                    >
                      <IntlMessages id="appModule.firstName" />
                    </label>
                    <Input
                      name="firstName"
                      id="sign-up-form-first-name"
                      disabled={areInputsDisabled}
                      className={errors.firstName && touched.firstName ? 'input-error' : ''}
                    />
                    {errors.firstName && touched.firstName && (
                      <div className="input-error-label">{errors.firstName}</div>
                    )}
                  </div>
                  <div className="input-group">
                    <label
                      htmlFor="sign-up-form-last-name"
                      className={`input-label${touched.lastName || values.lastName ? ' input-label-active' : ''}`}
                    >
                      <IntlMessages id="appModule.lastName" />
                    </label>
                    <Input
                      name="lastName"
                      id="sign-up-form-last-name"
                      disabled={areInputsDisabled}
                      className={errors.lastName && touched.lastName ? 'input-error' : ''}
                    />
                    {errors.lastName && touched.lastName && <div className="input-error-label">{errors.lastName}</div>}
                  </div>
                  <div className="input-group">
                    <label
                      htmlFor="sign-up-form-password"
                      className={`input-label${touched.password || values.password ? ' input-label-active' : ''}`}
                    >
                      <IntlMessages id="appModule.password" />
                    </label>
                    <Input.Password
                      name="password"
                      id="sign-up-form-password"
                      disabled={areInputsDisabled}
                      className={errors.password && touched.password ? 'input-error' : ''}
                    />
                    {errors.password && touched.password && <div className="input-error-label">{errors.password}</div>}
                  </div>
                  <div className="input-group">
                    <label
                      htmlFor="sign-up-form-confirm-password"
                      className={`input-label${
                        touched.confirmPassword || values.confirmPassword ? ' input-label-active' : ''
                      }`}
                    >
                      <IntlMessages id="appModule.confirmPassword" />
                    </label>
                    <Input.Password
                      name="confirmPassword"
                      id="sign-up-form-confirm-password"
                      disabled={areInputsDisabled}
                      className={errors.confirmPassword && touched.confirmPassword ? 'input-error' : ''}
                    />
                    {errors.confirmPassword && touched.confirmPassword && (
                      <div className="input-error-label">{errors.confirmPassword}</div>
                    )}
                  </div>
                  <Button className="submit-button" disabled={areInputsDisabled} htmlType="submit">
                    <IntlMessages id="app.userAuth.signUp" />
                  </Button>
                  <div className="gx-app-login-footer">
                    <IntlMessages id="app.userAuth.bySigning" />{' '}
                    <a href={config.gdprLink} className="link-by-sign-up">
                      <IntlMessages id="app.userAuth.bySigningLink" />
                    </a>
                  </div>
                </Space>
              </Form>
            )}
          </Formik>
        </Space>
      </div>
    </div>
  );
};

export default SignUp;
