import React, { useEffect } from 'react';

import IntlMessages from 'util/IntlMessages';
import { Button, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { selectSignedUserError, selectVerificationStatus } from 'appRedux/modules/auth/selectors';
import { Redirect } from 'react-router-dom';
import { getSignedUser, signOut } from 'appRedux/modules/auth/actions';
import { AuthStatus } from 'appRedux/modules/auth/types';
import CircularProgress from 'components/CircularProgress';

const VerifyEmailPage = () => {
  const dispatch = useDispatch();
  const error = useSelector(selectSignedUserError);
  const verificationStatus = useSelector(selectVerificationStatus);

  useEffect(() => {
    if (verificationStatus === AuthStatus.AUTHENTICATED) dispatch(getSignedUser());
  }, [verificationStatus]);

  if (error?.response?.status !== 441 && verificationStatus !== AuthStatus.PENDING) {
    return <Redirect to="/" />;
  }

  if (verificationStatus === AuthStatus.PENDING) return <CircularProgress />;

  return (
    <div className="gx-coming-soon">
      <Typography.Title level={1}>
        <IntlMessages id="appModule.verifyEmailTitle" />
      </Typography.Title>
      <Typography.Title level={3}>
        <IntlMessages id="appModule.verifyEmailText" />
      </Typography.Title>
      <Button onClick={() => dispatch(signOut())}>Sign out</Button>
    </div>
  );
};

export default VerifyEmailPage;
