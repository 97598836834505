import { ConfigurationAction, ConfigurationActionTypes } from './actions';
import { ConfigurationState } from './types';

const initialState: ConfigurationState = {
  configuration: {
    data: null,
    error: { create: null, get: null },
    loading: false,
    sending: false,
  },
  reconfigure: {
    error: null,
    sending: false,
  },
  price: {
    data: null,
    error: null,
    loading: false,
  },
  hardware: {
    data: null,
    error: null,
    loading: false,
  },
  currentConfigurationId: null,
};

export default (state = initialState, action: ConfigurationActionTypes): ConfigurationState => {
  switch (action.type) {
    case ConfigurationAction.GET_CONFIGURATIONS:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          loading: true,
        },
      };
    case ConfigurationAction.GET_CONFIGURATIONS_SUCCESS:
      const { configurations } = action.payload;
      return {
        ...state,
        configuration: {
          ...state.configuration,
          loading: false,
          error: { ...state.configuration.error, get: null },
          data: configurations,
        },
        currentConfigurationId: configurations.length > 0 ? configurations[0].id : null,
      };
    case ConfigurationAction.GET_CONFIGURATIONS_FAILURE:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          loading: false,
          error: { ...state.configuration.error, get: action.payload.error },
          data: [],
        },
        currentConfigurationId: null,
      };
    case ConfigurationAction.CREATE_CONFIGURATION:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          sending: true,
        },
      };
    case ConfigurationAction.CREATE_CONFIGURATION_SUCCESS:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          sending: false,
          data: [action.payload.configuration],
          error: { ...state.configuration.error, create: null },
        },
        currentConfigurationId: action.payload.configuration.id,
      };
    case ConfigurationAction.CREATE_CONFIGURATION_FAILURE:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          sending: false,
          error: { ...state.configuration.error, create: action.payload.error },
        },
        currentConfigurationId: null,
      };
    case ConfigurationAction.RECONFIGURE:
      return {
        ...state,
        reconfigure: {
          ...state.reconfigure,
          sending: true,
        },
      };
    case ConfigurationAction.RECONFIGURE_SUCCESS:
      return {
        ...state,
        reconfigure: {
          error: null,
          sending: false,
        },
      };
    case ConfigurationAction.RECONFIGURE_FAILURE:
      return {
        ...state,
        reconfigure: {
          error: action.payload.error,
          sending: false,
        },
      };
    case ConfigurationAction.GET_PRICE:
      return {
        ...state,
        price: {
          ...state.price,
          loading: true,
        },
      };
    case ConfigurationAction.GET_PRICE_SUCCESS:
      return {
        ...state,
        price: {
          ...state.price,
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    case ConfigurationAction.GET_PRICE_FAILURE:
      return {
        ...state,
        price: {
          ...state.price,
          loading: false,
          error: action.payload.error,
          data: null,
        },
      };
    case ConfigurationAction.GET_HARDWARE:
      return {
        ...state,
        hardware: {
          ...state.hardware,
          loading: true,
        },
      };
    case ConfigurationAction.GET_HARDWARE_SUCCESS:
      return {
        ...state,
        hardware: {
          ...state.hardware,
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    case ConfigurationAction.GET_HARDWARE_FAILURE:
      return {
        ...state,
        hardware: {
          ...state.hardware,
          loading: false,
          error: action.payload.error,
          data: null,
        },
      };
    case ConfigurationAction.RESET_CONFIGURATIONS:
      return initialState;
    default:
      return state;
  }
};
