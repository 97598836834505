import {
  DevicePostParams,
  Device,
  DevicesUrlParams,
  DeviceType,
  DeviceTypeCategory,
  DistributionBoard,
  CreateDistributionBoardBody,
} from './types';
import { AxiosError } from 'axios';

export enum DeviceAction {
  GET_DEVICE_TYPES = 'devices/GET_DEVICE_TYPES',
  GET_DEVICE_TYPES_SUCCESS = 'devices/GET_DEVICE_TYPES_SUCCESS',
  GET_DEVICE_TYPES_FAILURE = 'devices/GET_DEVICE_TYPES_FAILURE',

  GET_DEVICE_TYPE_CATAGORIES = 'devices/GET_DEVICE_TYPE_CATEGORIES',
  GET_DEVICE_TYPE_CATAGORIES_SUCCESS = 'devices/GET_DEVICE_TYPE_CATEGORIES_SUCCESS',
  GET_DEVICE_TYPE_CATAGORIES_FAILURE = 'devices/GET_DEVICE_TYPE_CATEGORIES_FAILURE',

  GET_DEVICES = 'devices/GET_DEVICES',
  GET_DEVICES_SUCCESS = 'devices/GET_DEVICES_SUCCESS',
  GET_DEVICES_FAILURE = 'devices/GET_DEVICES_FAILURE',

  CREATE_DEVICE = 'devices/CREATE_DEVICE',
  CREATE_DEVICE_SUCCESS = 'devices/CREATE_DEVICE_SUCCESS',
  CREATE_DEVICE_FAILURE = 'devices/CREATE_DEVICE_FAILURE',

  DELETE_DEVICE = 'devices/DELETE_DEVICE',
  DELETE_DEVICE_SUCCESS = 'devices/DELETE_DEVICE_SUCCESS',
  DELETE_DEVICE_FAILURE = 'devices/DELETE_DEVICE_FAILURE',

  GET_DISTRIBUTION_BOARDS = 'devices/GET_DISTRIBUTION_BOARDS',
  GET_DISTRIBUTION_BOARDS_SUCCESS = 'devices/GET_DISTRIBUTION_BOARDS_SUCCESS',
  GET_DISTRIBUTION_BOARDS_FAILURE = 'devices/GET_DISTRIBUTION_BOARDS_FAILURE',

  CREATE_DISTRIBUTION_BOARD = 'devices/CREATE_DISTRIBUTION_BOARD',
  CREATE_DISTRIBUTION_BOARD_SUCCESS = 'devices/CREATE_DISTRIBUTION_BOARD_SUCCESS',
  CREATE_DISTRIBUTION_BOARD_FAILURE = 'devices/CREATE_DISTRIBUTION_BOARD_FAILURE',

  RESET_DEVICES = 'devices/RESET_DEVICES',
}

type GetDevices = { type: DeviceAction.GET_DEVICES; payload: { query: DevicesUrlParams } };
export const getDevices = (query: DevicesUrlParams): GetDevices => {
  return {
    type: DeviceAction.GET_DEVICES,
    payload: { query },
  };
};

type GetDevicesSuccess = {
  type: DeviceAction.GET_DEVICES_SUCCESS;
  payload: { devices: Device[] };
};
export const getDevicesSuccess = (devices: Device[]): GetDevicesSuccess => {
  return {
    type: DeviceAction.GET_DEVICES_SUCCESS,
    payload: {
      devices,
    },
  };
};

type GetDevicesFailure = {
  type: DeviceAction.GET_DEVICES_FAILURE;
  payload: { error: AxiosError };
};
export const getDevicesFailure = (error: AxiosError): GetDevicesFailure => {
  return {
    type: DeviceAction.GET_DEVICES_FAILURE,
    payload: { error },
  };
};

type GetDeviceTypes = { type: DeviceAction.GET_DEVICE_TYPES };
export const getDeviceTypes = (): GetDeviceTypes => {
  return {
    type: DeviceAction.GET_DEVICE_TYPES,
  };
};

type GetDeviceTypesSuccess = { type: DeviceAction.GET_DEVICE_TYPES_SUCCESS; payload: { deviceTypes: DeviceType[] } };
export const getDeviceTypesSuccess = (deviceTypes: DeviceType[]): GetDeviceTypesSuccess => {
  return {
    type: DeviceAction.GET_DEVICE_TYPES_SUCCESS,
    payload: { deviceTypes },
  };
};

type GetDeviceTypesFailure = { type: DeviceAction.GET_DEVICE_TYPES_FAILURE; payload: { error: AxiosError } };
export const getDeviceTypesFailure = (error: AxiosError): GetDeviceTypesFailure => {
  return {
    type: DeviceAction.GET_DEVICE_TYPES_FAILURE,
    payload: { error },
  };
};

type CreateDevice = { type: DeviceAction.CREATE_DEVICE; payload: { data: DevicePostParams } };
export const createDevice = (data: DevicePostParams): CreateDevice => {
  return {
    type: DeviceAction.CREATE_DEVICE,
    payload: {
      data,
    },
  };
};

type CreateDeviceSuccess = { type: DeviceAction.CREATE_DEVICE_SUCCESS; payload: { device: Device } };
export const createDeviceSuccess = (device: DevicePostParams, id: number): CreateDeviceSuccess => {
  return {
    type: DeviceAction.CREATE_DEVICE_SUCCESS,
    payload: { device: { ...device, id } },
  };
};

type CreateDeviceFailure = { type: DeviceAction.CREATE_DEVICE_FAILURE; payload: { error: AxiosError } };
export const createDeviceFailure = (error: AxiosError): CreateDeviceFailure => {
  return {
    type: DeviceAction.CREATE_DEVICE_FAILURE,
    payload: {
      error,
    },
  };
};

type DeleteDevice = { type: DeviceAction.DELETE_DEVICE; payload: { id: number } };
export const deleteDevice = (id: number): DeleteDevice => ({
  type: DeviceAction.DELETE_DEVICE,
  payload: { id },
});

type DeleteDeviceSuccess = { type: DeviceAction.DELETE_DEVICE_SUCCESS; payload: { id: number } };
export const deleteDeviceSuccess = (id: number): DeleteDeviceSuccess => ({
  type: DeviceAction.DELETE_DEVICE_SUCCESS,
  payload: { id },
});

type DeleteDeviceFailure = { type: DeviceAction.DELETE_DEVICE_FAILURE; payload: { error: AxiosError } };
export const deleteDeviceFailure = (error: AxiosError): DeleteDeviceFailure => ({
  type: DeviceAction.DELETE_DEVICE_FAILURE,
  payload: { error },
});

type GetDeviceTypesCategories = { type: DeviceAction.GET_DEVICE_TYPE_CATAGORIES };
export const getDeviceTypesCategories = (): GetDeviceTypesCategories => {
  return {
    type: DeviceAction.GET_DEVICE_TYPE_CATAGORIES,
  };
};

type GetDeviceTypesCategoriesSuccess = {
  type: DeviceAction.GET_DEVICE_TYPE_CATAGORIES_SUCCESS;
  payload: { categories: DeviceTypeCategory[] };
};
export const getDeviceTypesCategoriesSuccess = (categories: DeviceTypeCategory[]): GetDeviceTypesCategoriesSuccess => {
  return {
    type: DeviceAction.GET_DEVICE_TYPE_CATAGORIES_SUCCESS,
    payload: { categories },
  };
};

type GetDeviceTypesCategoriesFailure = {
  type: DeviceAction.GET_DEVICE_TYPE_CATAGORIES_FAILURE;
  payload: { error: AxiosError };
};
export const getDeviceTypesCategoriesFailure = (error: AxiosError): GetDeviceTypesCategoriesFailure => {
  return {
    type: DeviceAction.GET_DEVICE_TYPE_CATAGORIES_FAILURE,
    payload: { error },
  };
};

type GetDistributionBoards = { type: DeviceAction.GET_DISTRIBUTION_BOARDS; payload: { configurationId: number } };
export const getDistributionBoards = (configurationId: number): GetDistributionBoards => ({
  type: DeviceAction.GET_DISTRIBUTION_BOARDS,
  payload: { configurationId },
});

type GetDistributionBoardsSuccess = {
  type: DeviceAction.GET_DISTRIBUTION_BOARDS_SUCCESS;
  payload: { distributionBoards: DistributionBoard[] };
};
export const getDistributionBoardsSuccess = (
  distributionBoards: DistributionBoard[],
): GetDistributionBoardsSuccess => ({
  type: DeviceAction.GET_DISTRIBUTION_BOARDS_SUCCESS,
  payload: { distributionBoards },
});

type GetDistributionBoardsFailure = {
  type: DeviceAction.GET_DISTRIBUTION_BOARDS_FAILURE;
  payload: { error: AxiosError };
};
export const getDistributionBoardsFailure = (error: AxiosError): GetDistributionBoardsFailure => ({
  type: DeviceAction.GET_DISTRIBUTION_BOARDS_FAILURE,
  payload: { error },
});

type CreateDistributionBoard = { type: DeviceAction.CREATE_DISTRIBUTION_BOARD; payload: CreateDistributionBoardBody };
export const createDistributionBoard = (distributionBoard: CreateDistributionBoardBody): CreateDistributionBoard => ({
  type: DeviceAction.CREATE_DISTRIBUTION_BOARD,
  payload: distributionBoard,
});

type CreateDistributionBoardSuccess = {
  type: DeviceAction.CREATE_DISTRIBUTION_BOARD_SUCCESS;
  payload: DistributionBoard;
};
export const createDistributionBoardSuccess = (
  distributionBoard: DistributionBoard,
): CreateDistributionBoardSuccess => ({
  type: DeviceAction.CREATE_DISTRIBUTION_BOARD_SUCCESS,
  payload: distributionBoard,
});

type CreateDistributionBoardFailure = {
  type: DeviceAction.CREATE_DISTRIBUTION_BOARD_FAILURE;
  payload: { error: AxiosError };
};
export const createDistributionBoardFailure = (error: AxiosError): CreateDistributionBoardFailure => ({
  type: DeviceAction.CREATE_DISTRIBUTION_BOARD_FAILURE,
  payload: { error },
});

type ResetDevices = { type: DeviceAction.RESET_DEVICES };
export const resetDevices = () => ({
  type: DeviceAction.RESET_DEVICES,
});

export type DeviceActionTypes =
  | GetDevices
  | GetDevicesSuccess
  | GetDevicesFailure
  | GetDeviceTypes
  | GetDeviceTypesSuccess
  | GetDeviceTypesFailure
  | GetDeviceTypesCategories
  | GetDeviceTypesCategoriesSuccess
  | GetDeviceTypesCategoriesFailure
  | CreateDevice
  | CreateDeviceSuccess
  | CreateDeviceFailure
  | DeleteDevice
  | DeleteDeviceSuccess
  | DeleteDeviceFailure
  | GetDistributionBoards
  | GetDistributionBoardsSuccess
  | GetDistributionBoardsFailure
  | CreateDistributionBoard
  | CreateDistributionBoardSuccess
  | CreateDistributionBoardFailure
  | ResetDevices;
