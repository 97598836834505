import { managementServiceApi } from '../../../util/api';
import {
  CreateDeviceResponse,
  CreateDistributionBoardBody,
  CreateDistributionBoardResponse,
  DevicePostParams,
  DevicesUrlParams,
  DeviceTypeCategory,
  DistributionBoard,
  GetDevicesResponse,
  GetDeviceTypeResponse,
} from './types';

export default {
  getDevices: (urlParams: DevicesUrlParams): Promise<GetDevicesResponse> => {
    return managementServiceApi(
      '/devices',
      {
        method: 'GET',
      },
      urlParams,
    );
  },
  getDeviceTypes: (): Promise<GetDeviceTypeResponse> => {
    return managementServiceApi('/deviceTypes', {
      method: 'GET',
    });
  },

  getDeviceTypesCategories: (): Promise<DeviceTypeCategory[]> => {
    return managementServiceApi('/deviceTypeCategories', { method: 'GET' });
  },

  createDevice: (data: DevicePostParams): Promise<CreateDeviceResponse> => {
    return managementServiceApi(`/devices`, {
      method: 'POST',
      body: data,
    });
  },

  deleteDevice: (id: number): Promise<void> => {
    return managementServiceApi(`/devices/${id}`, {
      method: 'DELETE',
    });
  },

  getDistributionBoards: (configurationId: number): Promise<DistributionBoard[]> => {
    return managementServiceApi(`/distributionBoards?configurationId=${configurationId}`, {
      method: 'GET',
    });
  },

  createDistributionBoard: (
    distributionBoard: CreateDistributionBoardBody,
  ): Promise<CreateDistributionBoardResponse> => {
    return managementServiceApi('distributionBoards', {
      method: 'POST',
      body: distributionBoard,
    });
  },
};
