import React from 'react';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { NotificationManager } from 'react-notifications';
import * as actions from './actions';
import apiService from './apiService';
import {
  CreateDeviceResponse,
  CreateDistributionBoardResponse,
  DeviceTypeCategory,
  DistributionBoard,
  GetDevicesResponse,
  GetDeviceTypeResponse,
} from './types';
import IntlMessages from '../../../util/IntlMessages';

function* watchGetDevices() {
  yield takeLatest(actions.DeviceAction.GET_DEVICES, function* handle(action: ReturnType<typeof actions.getDevices>) {
    try {
      const response: GetDevicesResponse = yield call(apiService.getDevices, action.payload.query);
      yield put(actions.getDevicesSuccess(response));
    } catch (e) {
      yield put(actions.getDevicesFailure(e));
      NotificationManager.error(<IntlMessages id="app.devices.getError" />);
    }
  });
}

function* watchGetDeviceTypes() {
  yield takeLatest(actions.DeviceAction.GET_DEVICE_TYPES, function* handle(
    action: ReturnType<typeof actions.getDeviceTypes>,
  ) {
    try {
      const response: GetDeviceTypeResponse = yield call(apiService.getDeviceTypes);
      yield put(actions.getDeviceTypesSuccess(response));
    } catch (e) {
      yield put(actions.getDevicesFailure(e));
      NotificationManager.error(<IntlMessages id="app.deviceTypes.getError" />);
    }
  });
}

function* watchCreateDevice() {
  yield takeLatest(actions.DeviceAction.CREATE_DEVICE, function* handle(
    action: ReturnType<typeof actions.createDevice>,
  ) {
    const device = action.payload.data;
    try {
      const response: CreateDeviceResponse = yield call(apiService.createDevice, device);
      yield put(actions.createDeviceSuccess(device, response.id));
    } catch (e) {
      yield put(actions.createDeviceFailure(e));
      NotificationManager.error(<IntlMessages id="app.device.createError" />);
    }
  });
}

function* watchDeleteDevice() {
  yield takeLatest(actions.DeviceAction.DELETE_DEVICE, function* handle(
    action: ReturnType<typeof actions.deleteDevice>,
  ) {
    const { id } = action.payload;
    try {
      yield call(apiService.deleteDevice, id);
      yield put(actions.deleteDeviceSuccess(id));
    } catch (error) {
      yield put(actions.deleteDeviceFailure(error));
    }
  });
}

function* watchGetDeviceTypeCategories() {
  yield takeLatest(actions.DeviceAction.GET_DEVICE_TYPE_CATAGORIES, function* handle(
    action: ReturnType<typeof actions.getDeviceTypesCategories>,
  ) {
    try {
      const response: DeviceTypeCategory[] = yield call(apiService.getDeviceTypesCategories);
      yield put(actions.getDeviceTypesCategoriesSuccess(response));
    } catch (error) {
      yield put(actions.getDeviceTypesCategoriesFailure(error));
    }
  });
}

function* watchGetDistributionBoards() {
  yield takeLatest(actions.DeviceAction.GET_DISTRIBUTION_BOARDS, function* handle(
    action: ReturnType<typeof actions.getDistributionBoards>,
  ) {
    try {
      const response: DistributionBoard[] = yield call(
        apiService.getDistributionBoards,
        action.payload.configurationId,
      );
      yield put(actions.getDistributionBoardsSuccess(response));
    } catch (error) {
      yield put(actions.getDistributionBoardsFailure(error));
    }
  });
}

function* watchCreateDistributionBoard() {
  yield takeLatest(actions.DeviceAction.CREATE_DISTRIBUTION_BOARD, function* handle(
    action: ReturnType<typeof actions.createDistributionBoard>,
  ) {
    const { name } = action.payload;
    try {
      const response: CreateDistributionBoardResponse = yield call(apiService.createDistributionBoard, action.payload);
      yield put(actions.createDistributionBoardSuccess({ id: response.id, name }));
    } catch (error) {
      yield put(actions.createDistributionBoardFailure(error));
    }
  });
}

export default function* devicesSage() {
  yield fork(watchGetDeviceTypes);
  yield fork(watchGetDevices);
  yield fork(watchCreateDevice);
  yield fork(watchDeleteDevice);
  yield fork(watchGetDeviceTypeCategories);
  yield fork(watchGetDistributionBoards);
  yield fork(watchCreateDistributionBoard);
}
