import React, { Component, ReactNode } from 'react';
import Nprogress from 'nprogress';
import ReactPlaceholder from 'react-placeholder';
import 'nprogress/nprogress.css';

import 'react-placeholder/lib/reactPlaceholder.css';
import CircularProgress from 'components/CircularProgress';

export default function asyncComponent(importComponent: any) {
  class AsyncFunc extends Component<{}, { component: ReactNode | null; mounted: boolean }> {
    constructor(props: any) {
      super(props);
      this.state = {
        component: null,
        mounted: false,
      };
    }

    componentWillMount() {
      Nprogress.start();
    }

    componentWillUnmount() {
      this.setState({ ...this.state, mounted: false });
    }

    async componentDidMount() {
      this.setState({ ...this.state, mounted: true });
      const { default: Component } = await importComponent();
      Nprogress.done();
      if (this.state.mounted) {
        this.setState({
          component: <Component {...this.props} />,
        });
      }
    }

    render() {
      const Component = this.state.component || <CircularProgress />;
      return (
        <ReactPlaceholder type="text" rows={7} ready={Component !== null}>
          {Component}
        </ReactPlaceholder>
      );
    }
  }

  return AsyncFunc;
}
