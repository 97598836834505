export enum SignUpLanguage {
  CS = 1,
  EN = 2,
}

export enum ServiceName {
  DEVICE = 'device',
  SECURITY = 'security',
  SCENE = 'scenes',
  LOGIC = 'logic',
}

export enum CibUnitKind {
  DIN = 1,
  BUILTIN = 2,
  DEVICE = 3,
}

export enum OpportunityState {
  NEW = 1,
  WAITING = 2,
  OFFER = 3,
  IN_PROGRESS = 4,
  EXECUTION = 5,
  ON_HOLD = 6,
  INACTIVE = 7,
  CANCELLED = 8,
}
