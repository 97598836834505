import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import VerifyEmailPage from '../../routes/VerifyEmail';
import asyncComponent from '../../util/asyncComponent';
import MainApp from '../MainApp';

const ProtectedApp = () => {
  const match = useRouteMatch();

  return (
    <>
      <Route path="/email-verification" component={VerifyEmailPage} />
      <Route
        path="/new-organization"
        component={asyncComponent(() => import('../../routes/NewOrganization'))}
        exact
        strict
      />
      <Route path={`${match.url}`} component={MainApp} />
    </>
  );
};

export default ProtectedApp;
