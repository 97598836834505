import configAlpha from './alpha';
import configBeta from './beta';
import configProd from './prod';

const getConfig = () => {
  if (window.location.origin.includes('alpha') || window.location.origin.includes('localhost')) {
    return configAlpha;
  }

  if (window.location.origin.includes('beta')) {
    return configBeta;
  }

  return configProd;
};

export default getConfig();
