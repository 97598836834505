import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { NotificationContainer } from 'react-notifications';
import 'assets/vendors/style';
import 'styles/wieldy.less';
import '@qest/qest-iot-icons/lib/ioticons.css';
import 'react-notifications/lib/notifications.css';

import { store, history, persistor } from './appRedux/store';
import CircularProgress from './components/CircularProgress';
import { queryClient } from './util/queryClient';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import SignIn from './routes/SignIn';
import SignUp from './routes/SignUp';
import AuthLayer from './application/AuthLayer';
import ProtectedApp from 'application/ProtectedApp';
import LocaleLayer from './application/LocaleLayer';

const Root = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={<CircularProgress />} persistor={persistor}>
        <ConnectedRouter history={history}>
          <QueryClientProvider client={queryClient}>
            <LocaleLayer>
              <Switch>
                <Route exact strict path="/sign-in" component={SignIn} />
                <Route exact strict path="/sign-up" component={SignUp} />
                <AuthLayer>
                  <ProtectedApp />
                </AuthLayer>
              </Switch>
              <NotificationContainer />
            </LocaleLayer>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
};

export default Root;
