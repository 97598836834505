import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  selectAuthTokenData,
  selectAuthStatus,
  selectSignedUser,
  selectSignedUserLoading,
  selectUserSending,
} from '../../appRedux/modules/auth/selectors';
import useInterval from '../../hooks/useInterval';
import { isTokenCloseToExpiration } from '../../util/tokenHelpers';
import { getSignedUser, refreshToken } from '../../appRedux/modules/auth/actions';
import { AuthStatus } from 'appRedux/modules/auth/types';

const MINUTE = 60 * 1000;
const REFRESH_TOKEN_CHECK_TIME = 10 * MINUTE;

const AuthLayer: FC = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const tokenData = useSelector(selectAuthTokenData);
  const authStatus = useSelector(selectAuthStatus);
  const isSignedUserLoading = useSelector(selectSignedUserLoading);
  const userSending = useSelector(selectUserSending);

  const signedUser = useSelector(selectSignedUser);

  useInterval(() => {
    if (
      tokenData &&
      isTokenCloseToExpiration(tokenData.tokenExpirationTimestamp) &&
      authStatus !== AuthStatus.PENDING
    ) {
      dispatch(refreshToken());
    }
  }, REFRESH_TOKEN_CHECK_TIME);

  useEffect(() => {
    if (tokenData && !isSignedUserLoading && !userSending) {
      dispatch(getSignedUser());
    }
  }, [signedUser?.id, tokenData?.access_token]);

  if (!tokenData && authStatus !== AuthStatus.REFRESHING && !['/sign-in', '/sign-up'].includes(location.pathname)) {
    history.push('/sign-in');
    return null;
  }

  return <>{children}</>;
};

export default AuthLayer;
