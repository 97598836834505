import React from 'react';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { NotificationManager } from 'react-notifications';
import * as actions from './actions';
import apiService from './apiService';
import { CreateFloorResponse, CreateRoomResponse, GetFloorsResponse, GetRoomsResponse } from './types';
import IntlMessages from '../../../util/IntlMessages';

function* watchGetFloors() {
  yield takeLatest(actions.HouseholdAction.GET_FLOORS, function* handle(action: ReturnType<typeof actions.getFloors>) {
    const { query } = action.payload;
    try {
      const response: GetFloorsResponse = yield call(apiService.getFloors, query);
      yield put(actions.getFloorsSuccess(response));
    } catch (e) {
      yield put(actions.getFloorsFailure(e));
      if (e.response.status !== 404)
        NotificationManager.error(<IntlMessages id="app.opportunity.calculator.getFloorsError" />);
    }
  });
}

function* watchCreateFloor() {
  yield takeLatest(actions.HouseholdAction.CREATE_FLOOR, function* handle(
    action: ReturnType<typeof actions.createFloor>,
  ) {
    try {
      const { id }: CreateFloorResponse = yield call(apiService.createFloor, action.payload.floor);
      yield put(actions.createFloorSuccess({ id, name: action.payload.floor.name }));
    } catch (e) {
      yield put(actions.getFloorsFailure(e));
      NotificationManager.error(<IntlMessages id="app.opportunity.calculator.createFloorError" />);
    }
  });
}

function* watchUpdateFloor() {
  yield takeLatest(actions.HouseholdAction.UPDATE_FLOOR, function* handle(
    action: ReturnType<typeof actions.updateFloor>,
  ) {
    const { floor, floorId } = action.payload;
    try {
      yield call(apiService.updateFloor, floor, floorId);
      yield put(actions.updateFloorSuccess());
    } catch (e) {
      yield put(actions.getFloorsFailure(e));
      NotificationManager.error(<IntlMessages id="app.household.updateFloorsError" />);
    }
  });
}

function* watchDeleteFloor() {
  yield takeLatest(actions.HouseholdAction.DELETE_FLOOR, function* handle(
    action: ReturnType<typeof actions.deleteFloor>,
  ) {
    const { floorId } = action.payload;
    try {
      yield call(apiService.deleteFloor, floorId);
      yield put(actions.deleteFloorSuccess(floorId));
    } catch (e) {
      yield put(actions.deleteFloorFailure(e));
      NotificationManager.error(<IntlMessages id="app.opportunity.calculator.deleteFloorError" />);
    }
  });
}

function* watchGetRooms() {
  yield takeLatest(actions.HouseholdAction.GET_ROOMS, function* handle(action: ReturnType<typeof actions.getRooms>) {
    try {
      const { query } = action.payload;
      const response: GetRoomsResponse = yield call(apiService.getRooms, query);
      yield put(actions.getRoomsSuccess(response));
    } catch (e) {
      yield put(actions.getRoomsFailure(e));
      if (e.response?.status !== 404)
        NotificationManager.error(<IntlMessages id="app.opportunity.calculator.getRoomsError" />);
    }
  });
}

function* watchCreateRoom() {
  yield takeLatest(actions.HouseholdAction.CREATE_ROOM, function* handle(
    action: ReturnType<typeof actions.createRoom>,
  ) {
    const { room } = action.payload;
    try {
      const response: CreateRoomResponse = yield call(apiService.createRoom, room);
      yield put(actions.createRoomSuccess({ ...action.payload.room, id: response.id }));
    } catch (e) {
      yield put(actions.createRoomFailure(e));
      NotificationManager.error(<IntlMessages id="app.opportunity.calculator.createRoom" />);
    }
  });
}

function* watchUpdateRoom() {
  yield takeLatest(actions.HouseholdAction.UPDATE_ROOM, function* handle(
    action: ReturnType<typeof actions.updateRoom>,
  ) {
    const { room, roomId } = action.payload;
    try {
      yield call(apiService.updateRoom, room, roomId);
      yield put(actions.updateRoomSuccess());
    } catch (e) {
      yield put(actions.updateRoomFailure(e));
      NotificationManager.error(<IntlMessages id="app.household.updateRoomError" />);
    }
  });
}

function* watchDeleteRoom() {
  yield takeLatest(actions.HouseholdAction.DELETE_ROOM, function* handle(
    action: ReturnType<typeof actions.deleteRoom>,
  ) {
    const { roomId } = action.payload;
    try {
      yield call(apiService.deleteRoom, roomId);
      yield put(actions.deleteRoomSuccess(roomId));
    } catch (e) {
      yield put(actions.deleteRoomFailure(e));
      NotificationManager.error(<IntlMessages id="app.opportunity.calculator.deleteRoom" />);
    }
  });
}

export default function* householdSaga() {
  yield fork(watchGetRooms);
  yield fork(watchCreateRoom);
  yield fork(watchUpdateRoom);
  yield fork(watchDeleteRoom);
  yield fork(watchGetFloors);
  yield fork(watchCreateFloor);
  yield fork(watchUpdateFloor);
  yield fork(watchDeleteFloor);
}
